import { COLORS } from "./constants";
import fdebug from "debug";

// eslint-disable-next-line no-unused-vars
const debug = fdebug("app:Context");

import Note from "../services/score/Note";

/**
 * @type Array<Note>
 */
const paintedNotes: Note[] = [];

/**
 * @param {Note} note
 * @param {String} color
 */
const paintNote = (note: Note, color: string) => {
  note.paint(color);
  paintedNotes.push(note);
};

/**
 *
 * @param {Note[]} notes
 * @param {String} color
 */
const paintNotes = (notes: Note[], color: string) => {
  notes.forEach((note) => {
    paintNote(note, color);
  });
};

const resetPaintedNotes = () => {
  paintedNotes.forEach((note) => {
    note.paint(COLORS.DEFAULT);
  });
};

export { paintNote, paintNotes, resetPaintedNotes };
