import React, { useState } from "react";
import ExerciseComponent from "./exercise";
import { Routes, Route } from "react-router-dom";

import fdebug from "debug";
import ExercisePicker from "./ExercisePicker/ExercisePicker";
import NavBar from "./AppBar";
const debug = fdebug("app:exerciseComponent");

const Root = () => {
  const [shouldRefresh, setShouldRefresh] = useState(false);
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<ExercisePicker shouldRefresh={shouldRefresh} />}
        />
        <Route
          path="/random"
          element={<ExerciseComponent setShouldRefresh={setShouldRefresh} />}
        />
        <Route
          path="/exercise"
          element={<ExerciseComponent setShouldRefresh={setShouldRefresh} />}
        />
      </Routes>
    </>
  );
};

export default Root;
