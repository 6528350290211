import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ShareIcon from "@mui/icons-material/Share";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Grid,
  IconButton,
  IconButtonProps,
  Modal,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { Dispatch, SetStateAction } from "react";

interface ConnectYourKeyboardModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const MidiSection = () => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title={`Midi Port`} />
      <CardMedia
        component="img"
        height="100"
        image={`${process.env.PUBLIC_URL}/media/midi-port.jpg`}
        alt="USB-B Port"
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          In this case you need a <b>Midi to USB interface</b>.
        </Typography>
      </CardContent>
      {/* <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Method:</Typography>
          <Typography paragraph>
            Heat 1/2 cup of the broth in a pot until simmering, add saffron and
            set aside for 10 minutes.
          </Typography>
        </CardContent>
      </Collapse> */}
    </Card>
  );
};

const UsbBSection = () => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title={`USB-B Port`} />
      <CardMedia
        component="img"
        height="100"
        image={`${process.env.PUBLIC_URL}/media/usb-b.jpg`}
        alt="USB-B Port"
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          In this case you need a USB-B to USB-B or{" "}
          <b>&quot;Printer&quot; Cable</b>
        </Typography>
      </CardContent>
      {/* <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Method:</Typography>
          <Typography paragraph>
            Heat 1/2 cup of the broth in a pot until simmering, add saffron and
            set aside for 10 minutes.
          </Typography>
        </CardContent>
      </Collapse> */}
    </Card>
  );
};

const ConnectYourKeyboardModal = ({
  open,
  setOpen,
}: ConnectYourKeyboardModalProps) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      disableScrollLock={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{}}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          overflow: "scroll",
          maxHeight: "70vh",
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: "1rem" }}
        >
          Connect your keyboard
        </Typography>

        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ py: "1rem" }}>
              Connect it to your computer (any OS)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ my: 1 }}>
              1. Identify the port on your keyboard
            </Typography>
            <Grid
              container
              spacing={1}
              sx={{ display: "flex", alignItems: "stretch" }}
            >
              <Grid item xs={6}>
                <UsbBSection />
              </Grid>
              <Grid item xs={6}>
                <MidiSection />
              </Grid>
            </Grid>
            <Typography sx={{ my: 1 }}>
              2. Connect the keyboard to your computer using the cable indicated
              in step 1.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ py: "1rem" }}>
              Connect it to an Android tablet or phone
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Follow the steps of the &quot;Connect it to your computer&quot;
              section above and then get <b>a USB-A to USB-C adapter</b>.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ py: "1rem" }}>
              Connect it to an Iphone or Ipad
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              This app is currently not supported on Safari for Iphone/Ipad as
              WebMidi is not supported by them. Currently working on providing a
              workaround.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Modal>
  );
};

export default ConnectYourKeyboardModal;
