import metronome from './metronome';
import fdebug from 'debug';
const debug = fdebug('app:metronomeUI');
let beatRects = [];

/**
 * @type createjs.Stage
 */
let stage;
let rectWidth = 30;
let rectHeight = 10;

// eslint-disable-next-line no-unused-vars
let worker = null;

/** */
function init(canvasRef) {
  stage = new createjs.Stage(canvasRef);
  const availableSpace = Math.min(document.getElementById('metronomeCanvasContainer').clientWidth, 400);
  rectWidth = availableSpace * 0.8 / metronome.getTimesPerMeasure();
  for (let i = 0; i < metronome.getTimesPerMeasure(); i++) {
    let shape = new createjs.Shape();
    beatRects.push(shape);
    paintBeat(i, 'deepSkyBlue');
    stage.addChild(shape);
    stage.update();
  }
  stage.update();
  // debug('ended initui: ');
  // debug(beatRects);
}

/** */
function reset() {
  resetBeatsColors();
}

/**
 *
 * @param {Number} i Index of beat to paint.
 */
function tick(i) {
  // debug('ui: drawing beat ' + i);
  if (i === 0) {
    resetBeatsColors();
  }
  paintBeat(i, 'darkBlue');
  stage.update();
}

/** */
function resetBeatsColors() {
  for (let i = 0; i < beatRects.length; i++) {
    paintBeat(i, 'DeepSkyBlue');
  }
  stage.update();
}

/**
 *
 * @param {Number} i Index of the beat to be painted.
 * @param {String} color color.
 */
function paintBeat(i, color) {
  beatRects[i].graphics.clear();
  beatRects[i].graphics.beginFill(color)
    .drawRect(i * (rectWidth * 1.1) + 10, 10, rectWidth, rectHeight);
}

/** */
function start() {
  worker = setInterval(
    () => {
      if (metronome.currentBeat() == -1) return;
      // debug('current beat: ' + metronome.currentBeat());
      let index = metronome.currentBeat() % metronome.beatsPerMeasure;
      if (index === 0) {
        // debug('beat 0 resetting');
        resetBeatsColors();
        // drawBeatPassed(index);
      } else {
        // debug('painting beat ' + index);
        // drawBeatPassed(index);
      }
      stage.update();
    }, 1000 / 60
  );
}

export default {
  init,
  start,
  tick,
  reset,
};
