import { SvgIcon } from "@mui/material";
import * as React from "react";
import { Dot } from "./dot";

const Black = ({ className, dotted = false }: { className: string, dotted: boolean }) => {
  return (
    <SvgIcon className={className} viewBox="0 0 20 40">
      <g className="vf-note">
        <g className="vf-stem">
          <path
            strokeWidth="1.5"
            fill="none"
            stroke="black"
            strokeDasharray="none"
            d="M 15 31 L 15 4"
          ></path>
        </g>
        <g className="vf-notehead">
          <path
            stroke-width="0.3"
            stroke="none"
            fill="black"
            stroke-dasharray="none"
            d="M 11.0295 25.7771 C 11.1418 25.7771 11.3103 25.7771 11.3384 25.7771 C 11.3384 25.7771 11.3664 25.7771 11.3664 25.7771 C 11.3664 25.7771 11.5349 25.7771 11.6753 25.7771 C 14.2025 25.9175 15.6908 27.5742 15.6908 29.6522 C 15.6908 30.663 15.3538 31.8143 14.5114 32.9094 C 12.9108 35.0716 10.2994 36.251 8.0249 36.251 C 6.2559 36.251 4.7396 35.549 4.0095 34.1169 C 3.7848 33.5553 3.6725 33.0218 3.6725 32.4321 C 3.6725 29.3714 7.0983 25.9737 11.0295 25.7771"
          ></path>
        </g>
        {dotted && <Dot />}
      </g>
    </SvgIcon>
  );
};

export default Black;
