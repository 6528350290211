import { assert } from "console";
import { Rythm, RythmElement } from "../../types/Rythm";
import { NoteDuration } from "../../types/noteDuration";

export const getRythmDuration = (rythm: Rythm): number => {
    if (rythm == null) {debugger}
    return rythm.elements.reduce((acc, val) => acc + getRythmElementDuration(val), 0)
}

export const getRythmElementDuration = (el: RythmElement): number => {
    const base = 1 / el.duration

    return base + (el.dotted ? base / 2 : 0)
}

export const createRythmElement = (duration: NoteDuration) => createRythmElementWithDefaults({ duration: duration })

export const createRythmElementWithDefaults = ({ duration, dotted = false }: { duration: NoteDuration, dotted?: boolean }): RythmElement => ({
    dotted: dotted || false,
    duration: duration,
})