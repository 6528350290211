/* eslint-disable max-len */
import { getRythmById } from "../../data/rythms";
import { RandomExerciseConfig } from "../../types/RandomExerciseConfig";


const levels: RandomExerciseConfig[] = [
  {
    key: "C",
    numberOfBars: 20,
    noteFrom: "C/4",
    noteTo: "C/4",
    tension: "TONIC",
    progressionIndex: 2,
    rythmIds: ['half'],
  },
  {
    key: "C",
    numberOfBars: 20,
    noteFrom: "C/4",
    noteTo: "C/4",
    tension: "TONIC",
    progressionIndex: 2,
    rythmIds: ['half', 'quarter'],
  },
  {
    key: "C",
    numberOfBars: 20,
    noteFrom: "C/4",
    noteTo: "D/4",
    tension: "TONIC",
    progressionIndex: 2,
    rythmIds: ['half', 'quarter'],
  },
  {
    key: "C",
    numberOfBars: 20,
    noteFrom: "C/4",
    noteTo: "D/4",
    tension: "TONIC",
    progressionIndex: 2,
    rythmIds: ['half', 'quarter'],
  },
  {
    key: "C",
    numberOfBars: 20,
    noteFrom: "C/4",
    noteTo: "C/4",
    tension: "TONIC",
    progressionIndex: 2,
    rythmIds: ['quarter', 'eight'],
  },
  {
    key: "C",
    numberOfBars: 20,
    noteFrom: "C/4",
    noteTo: "C/4",
    tension: "TONIC",
    progressionIndex: 2,
    rythmIds: ['half', 'quarter', 'eight'],
  },
  {
    key: "C",
    numberOfBars: 20,
    noteFrom: "C/4",
    noteTo: "D/4",
    tension: "TONIC",
    progressionIndex: 2,
    rythmIds: ['quarter', 'eight'],
  },
  {
    key: "C",
    numberOfBars: 20,
    noteFrom: "C/4",
    noteTo: "E/4",
    tension: "TONIC",
    progressionIndex: 2,
    rythmIds: ['quarter', 'eight'],
  },
  {
    key: "G",
    numberOfBars: 20,
    noteFrom: "C/4",
    noteTo: "E/4",
    tension: "TONIC",
    progressionIndex: 2,
    rythmIds: ['quarter', 'eight'],
  },
  // intermediate
  {
    key: "C",
    numberOfBars: 20,
    noteFrom: "C/4",
    noteTo: "E/4",
    tension: "TONIC",
    progressionIndex: 2,
    rythmIds: ['half', 'quarter', 'eight'],
  },
  {
    key: "C",
    numberOfBars: 20,
    noteFrom: "C/4",
    noteTo: "E/4",
    tension: "TONIC",
    progressionIndex: 2,
    rythmIds: ['half', 'quarter'],
  },
  {
    key: "C",
    numberOfBars: 20,
    noteFrom: "C/4",
    noteTo: "D/4",
    tension: "TONIC",
    progressionIndex: 2,
    rythmIds: ['half', 'quarter', 'eight'],
  },
  {
    key: "C",
    numberOfBars: 20,
    noteFrom: "C/4",
    noteTo: "E/4",
    tension: "TONIC",
    progressionIndex: 2,
    rythmIds: ['half', 'quarter', 'eight'],
  },
  {
    key: "C",
    numberOfBars: 20,
    noteFrom: "C/4",
    noteTo: "F/4",
    tension: "TONIC",
    progressionIndex: 2,
    rythmIds: ['half', 'quarter', 'eight'],
  },
  {
    key: "C",
    numberOfBars: 20,
    noteFrom: "C/4",
    noteTo: "C/5",
    tension: "THIRD",
    progressionIndex: 2,
    rythmIds: ['half', 'quarter', 'eight'],
  },
  {
    key: "F",
    numberOfBars: 20,
    noteFrom: "C/4",
    noteTo: "C/5",
    tension: "THIRD",
    progressionIndex: 2,
    rythmIds: ['half', 'quarter', 'eight'],
  },
  {
    key: "C",
    numberOfBars: 20,
    noteFrom: "C/4",
    noteTo: "C/5",
    tension: "TRIAD",
    progressionIndex: 2,
    rythmIds: ['quarter', 'eight'],
  },
  {
    key: "E",
    numberOfBars: 20,
    noteFrom: "C/4",
    noteTo: "C/5",
    tension: "TETRAD",
    progressionIndex: 2,
    rythmIds: ['quarter', 'eight'],
  },
  {
    key: "Ab",
    numberOfBars: 20,
    noteFrom: "D/4",
    noteTo: "E/5",
    tension: "TETRAD",
    progressionIndex: 0,
    rythmIds: ['dotted-quarter-and-eight', 'eight'],
  },
  {
    key: "C#",
    numberOfBars: 20,
    noteFrom: "A/4",
    noteTo: "C/5",
    tension: "TETRAD",
    progressionIndex: 0,
    rythmIds: ['dotted-quarter-and-eight', 'dotted-eight-and-sixteenth'],
  },
  {
    key: "Db",
    numberOfBars: 20,
    noteFrom: "E/4",
    noteTo: "B/4",
    tension: "TETRAD",
    progressionIndex: 2,
    rythmIds: ['dotted-quarter-and-eight', 'sixteenth', 'dotted-eight-and-sixteenth'],
  },
]



export default levels;
