import Note from "./Note"; // eslint-disable-line no-unused-vars
import vex from "vexflow"; // eslint-disable-line no-unused-vars
import Voice from "./Voice";
// https://github.com/Microsoft/TypeScript/issues/14377

/**
 * Class representing a Stave
 * @class
 * @memberof Score
 */
export default class Stave {
  /**
   * @constructor
   * @param {Number} measureIndex
   * @param {Voice[]} voices
   * @param {vex.Flow.Stave} vfStave
   */
  constructor(measureIndex, voices, vfStave = null) {
    this.measureIndex = measureIndex;
    this.voices = voices;
    this.vfStave = vfStave;
  }
}
