/**
 * @type {import("../../../types/exerciseInput/exercise").InputExercise}
 */
export default {
  name: "Chopin Berceuse",
  id: "examples~chopinBerceuse",
  config: {
    time: "6/8",
    keySignature: "Db",
    clefs: ["treble", "bass"],
  },
  measures: [
    {
      staves: [
        {
          voices: [
            {
              notes: [
                {
                  keys: ["D/5"],
                  duration: 2,
                  options: { rest: true, dotted: true },
                },
              ],
            },
          ],
        },
        {
          voices: [
            {
              notes: [
                { keys: ["D/2"], duration: 8 },
                { keys: ["A/3"], duration: 8 },
                { keys: ["D/4", "F/4"], duration: 8 },
                { keys: ["C/4", "G/4"], duration: 8 },
                { keys: ["A/3"], duration: 4 },
              ],
            },
          ],
        },
      ],
    },
    {
      staves: [
        {
          // Measure 2
          voices: [
            {
              notes: [
                {
                  keys: ["D/5"],
                  duration: 2,
                  options: { rest: true, dotted: true },
                },
              ],
            },
          ],
        },
        {
          voices: [
            {
              notes: [
                { keys: ["D/2"], duration: 8 },
                { keys: ["A/3"], duration: 8 },
                { keys: ["D/4", "F/4"], duration: 8 },
                { keys: ["C/4", "G/4"], duration: 8 },
                { keys: ["A/3"], duration: 4 },
              ],
            },
          ],
        },
      ],
    },
    {
      // Measure 3
      staves: [
        {
          voices: [
            {
              notes: [
                { keys: ["F/5"], duration: 4, options: { dotted: true } },
                { keys: ["E/5"], duration: 8 },
                { keys: ["A/5"], duration: 8 },
                { keys: ["F/5"], duration: 8 },
              ],
            },
          ],
        },
        {
          voices: [
            {
              notes: [
                { keys: ["D/2"], duration: 8 },
                { keys: ["A/3"], duration: 8 },
                { keys: ["D/4", "F/4"], duration: 8 },
                { keys: ["C/4", "G/4"], duration: 8 },
                { keys: ["A/3"], duration: 4 },
              ],
            },
          ],
        },
      ],
    },
    {
      // Measure 4
      staves: [
        {
          voices: [
            {
              notes: [
                { keys: ["E/5"], duration: 8 },
                { keys: ["F/5"], duration: 8 },
                { keys: ["D/5"], duration: 8 },
                { keys: ["B/4"], duration: 8 },
                { keys: ["C/5"], duration: 8 },
                { keys: ["E/5"], duration: 8 },
              ],
            },
          ],
        },
        {
          voices: [
            {
              notes: [
                { keys: ["D/2"], duration: 8 },
                { keys: ["A/3"], duration: 8 },
                { keys: ["D/4", "F/4"], duration: 8 },
                { keys: ["C/4", "G/4"], duration: 8 },
                { keys: ["A/3"], duration: 4 },
              ],
            },
          ],
        },
      ],
    },
    {
      // Measure 5
      staves: [
        {
          voices: [
            {
              notes: [
                { keys: ["A/4"], duration: 8 },
                { keys: ["D/5"], duration: 4 },
                { keys: ["E/5"], duration: 8 },
                { keys: ["G/5"], duration: 8 },
                { keys: ["B/5"], duration: 8 },
              ],
            },
          ],
        },
        {
          voices: [
            {
              notes: [
                { keys: ["D/2"], duration: 8 },
                { keys: ["A/3"], duration: 8 },
                { keys: ["D/4", "F/4"], duration: 8 },
                { keys: ["C/4", "G/4"], duration: 8 },
                { keys: ["A/3"], duration: 4 },
              ],
            },
          ],
        },
      ],
    },
    {
      // Measure 6
      staves: [
        {
          voices: [
            {
              notes: [
                { keys: ["A/5"], duration: 8 },
                { keys: ["G/5"], duration: 8 },
                { keys: ["F/5"], duration: 8 },
                { keys: ["E/5"], duration: 8 },
                { keys: ["D/5"], duration: 8 },
                { keys: ["C/5", "E/5"], duration: 8 },
              ],
            },
          ],
        },
        {
          voices: [
            {
              notes: [
                { keys: ["D/2"], duration: 8 },
                { keys: ["A/3"], duration: 8 },
                { keys: ["D/4", "F/4"], duration: 8 },
                { keys: ["C/4", "G/4"], duration: 8 },
                { keys: ["A/3"], duration: 4 },
              ],
            },
          ],
        },
      ],
    },
    {
      // Measure 7
      staves: [
        {
          voices: [
            {
              notes: [
                { keys: ["D/5", "F/5"], duration: 8 },
                { keys: ["C/5"], duration: 8 },
                { keys: ["B/4"], duration: 8 },
                { keys: ["C/5", "E/5"], duration: 8 },
                { keys: ["B/4", "A/5"], duration: 8 },
                { keys: ["A/4", "F/5"], duration: 8 },
              ],
            },
          ],
        },
        {
          voices: [
            {
              notes: [
                { keys: ["D/2"], duration: 8 },
                { keys: ["A/3"], duration: 8 },
                { keys: ["D/4", "F/4"], duration: 8 },
                { keys: ["C/4", "G/4"], duration: 8 },
                { keys: ["A/3"], duration: 4 },
              ],
            },
          ],
        },
      ],
    },
    {
      // Measure 8
      staves: [
        {
          voices: [
            {
              notes: [
                { keys: ["B/4", "E/5"], duration: 8 },
                { keys: ["A/4", "F/5"], duration: 8 },
                { keys: ["F/4", "D/5"], duration: 8 },
                { keys: ["G/4", "B/4"], duration: 8 },
                { keys: ["F/4", "C/5"], duration: 8 },
                { keys: ["E/4", "E/5"], duration: 8 },
              ],
            },
          ],
        },
        {
          voices: [
            {
              notes: [
                { keys: ["D/2"], duration: 8 },
                { keys: ["A/3"], duration: 8 },
                { keys: ["D/4", "F/4"], duration: 8 },
                { keys: ["C/4", "G/4"], duration: 8 },
                { keys: ["A/3"], duration: 4 },
              ],
            },
          ],
        },
      ],
    },
    {
      // Measure 9
      staves: [
        {
          voices: [
            {
              notes: [
                { keys: ["G/4", "A/4"], duration: 8 },
                { keys: ["F/4", "D/5"], duration: 8 },
                { keys: ["B/4"], duration: 8 },
                { keys: ["C/5", "E/5"], duration: 8 },
                { keys: ["B/4", "G/5"], duration: 8 },
                { keys: ["A/4", "B/5"], duration: 8 },
              ],
            },
          ],
        },
        {
          voices: [
            {
              notes: [
                { keys: ["D/2"], duration: 8 },
                { keys: ["A/3"], duration: 8 },
                { keys: ["D/4", "F/4"], duration: 8 },
                { keys: ["C/4", "G/4"], duration: 8 },
                { keys: ["A/3"], duration: 4 },
              ],
            },
          ],
        },
      ],
    },
    {
      // Measure 10
      staves: [
        {
          voices: [
            {
              notes: [
                { keys: ["F/5", "A/5"], duration: 8 },
                { keys: ["C/5", "D/6"], duration: 8 },
                { keys: ["D/5", "B/5"], duration: 8 },
                { keys: ["E/5", "G/5"], duration: 8 },
                { keys: ["B/4", "C/6"], duration: 8 },
                { keys: ["C/5", "A/5"], duration: 8 },
              ],
            },
          ],
        },
        {
          voices: [
            {
              notes: [
                { keys: ["D/2"], duration: 8 },
                { keys: ["A/3"], duration: 8 },
                { keys: ["D/4", "F/4"], duration: 8 },
                { keys: ["C/4", "G/4"], duration: 8 },
                { keys: ["A/3"], duration: 4 },
              ],
            },
          ],
        },
      ],
    },
    {
      // Measure 11
      staves: [
        {
          voices: [
            {
              notes: [
                { keys: ["D/5", "F/5"], duration: 8 },
                { keys: ["A/4", "B/5"], duration: 8 },
                { keys: ["B/4", "G/5"], duration: 8 },
                { keys: ["C/5", "E/5"], duration: 8 },
                { keys: ["G/4", "A/5"], duration: 8 },
                { keys: ["A/4", "F/5"], duration: 8 },
              ],
            },
          ],
        },
        {
          voices: [
            {
              notes: [
                { keys: ["D/2"], duration: 8 },
                { keys: ["A/3"], duration: 8 },
                { keys: ["D/4", "F/4"], duration: 8 },
                { keys: ["C/4", "G/4"], duration: 8 },
                { keys: ["A/3"], duration: 4 },
              ],
            },
          ],
        },
      ],
    },
    {
      // Measure 12
      staves: [
        {
          voices: [
            {
              notes: [
                { keys: ["B/4", "E/5"], duration: 8 },
                { keys: ["F/4", "F/5"], duration: 8 },
                {
                  keys: ["A/4", "D/5"],
                  duration: 8,
                  options: { tieStart: [0] },
                },
                { keys: ["A/4", "B/4"], duration: 8, options: { tieEnd: [0] } },
                { keys: ["Gn/4", "C/5"], duration: 8 },
                {
                  keys: ["Gb/4", "E/5"],
                  duration: 8,
                  options: { tieStart: [0] },
                },
              ],
            },
          ],
        },
        {
          voices: [
            {
              notes: [
                { keys: ["D/2"], duration: 8 },
                { keys: ["A/3"], duration: 8 },
                { keys: ["D/4", "F/4"], duration: 8 },
                { keys: ["C/4", "G/4"], duration: 8 },
                { keys: ["A/3"], duration: 4 },
              ],
            },
          ],
        },
      ],
    },
  ],
};
