// eslint-disable-next-line no-unused-vars
import Stave from './Stave';
/**
 * @class measure
 * @memberof Score
*/
class Measure {
  /**
   * Measure constructor
   * @param {Stave[]} staves
   * @param {Number} index
  */
  constructor(staves = [], index = -1) {
    this.staves = staves;
    this.index = index;
  }

  /**
   * @param {Stave} stave
  */
  addStave(stave) {
    this.staves.push(stave);
  }
}

export default Measure;
