import { MAJOR_KEYS } from "../../types/MajorKeys";
import { UnalteredNoteWithOctave } from "../../types/UnalteredNoteWithOctave";
import { UNALTERED_NOTES } from "../../types/UnalteredNotes";
import { getNoteForKeyAndDegree } from "../keyService";
import { UnalteredNoteName } from "../../types/UnalteredNoteName";
import { applyKeySignature, distance } from "../keyService/service";
import { NoteNameWithOctave } from "../../types/NoteNameWithOctave";
import { NoteName } from "../../types/NoteName";
import { MajorKey } from "../../types/MajorKey";

const generateChordNotes = (
  key: MajorKey,
  degree: number,
  tensions: number[]
): UNALTERED_NOTES[] => {
  const chordNotes: UNALTERED_NOTES[] = [];

  tensions.forEach((tension) => {
    chordNotes.push(getNoteForKeyAndDegree(key, degree + tension - 1));
  });

  return chordNotes;
};

const applyKeySignatureToChord = (
  key: MAJOR_KEYS,
  chord: (UnalteredNoteName | UnalteredNoteWithOctave)[]
): (NoteName | NoteNameWithOctave)[] => {
  return chord.map((note) => {
    return applyKeySignature(note, key);
  });
};

const addOctavesToNotes = (
  notes: UnalteredNoteName[],
  closerTo?: UnalteredNoteWithOctave
): UnalteredNoteWithOctave[] => {
  const [targetNote, targetOctaveStr] = closerTo.split("/");
  const targetOctave = parseInt(targetOctaveStr);
  const octaveOptions = [targetOctave - 1, targetOctave, targetOctave + 1];

  return notes.map((note) => {
    let bestOption = null;
    let currentDistance = 10000;
    octaveOptions.forEach((octave) => {
      const candidate: UnalteredNoteWithOctave =
        `${note}/${octave}` as UnalteredNoteWithOctave;
      const dToTarget = distance(closerTo, candidate);

      if (
        bestOption == null ||
        dToTarget < currentDistance ||
        (dToTarget == currentDistance && octave == targetOctave)
      ) {
        bestOption = candidate;
        currentDistance = dToTarget;
      }
    });

    return bestOption;
  });
};

export { generateChordNotes, addOctavesToNotes, applyKeySignatureToChord };
