// eslint-disable-next-line no-unused-vars
import Measure from './Measure';

/**
 * @class
*/
export default class ExerciseNotes {
  /**
   * @constructor
   * @param {Measure[]} measures
  */
  constructor(measures = []) {
    this.timeNumerator = null;
    this.timeDenominator = null;
    this._measures = [];
  }

  /**
   * @return {Measure[]}
   */
  get measures() {
    return this._measures;
  }

  /**
   * @param {Measure[]} measures;
   */
  set measures(measures) {
    this._measures = measures;
  }

  /**
   *
   * @param {Measure} measure
   */
  addMeasure(measure) {
    this.measures.push(measure);
  }
}
