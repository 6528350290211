import {
  Box,
  Slider,
  OutlinedInput,
  Typography,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useEffect } from "react";
import levelCategories from "../../services/randomGenerator/levels";
import { RandomExerciseConfig } from "../../types/RandomExerciseConfig";
import { useStickyState } from "../lib/stickyState";
import levels from "../../services/randomGenerator/levels";

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

interface LevelPickerProps {
  setExerciseParams: (params: RandomExerciseConfig) => void;
}

const LevelPicker = ({ setExerciseParams }: LevelPickerProps) => {
  const [selectedLevel, setSelectedLevel] = useStickyState<number>(
    0,
    "selectedLevel"
  );


  const handleLevelChange = (newValue) => {
    if (newValue < 0) newValue = 0;
    setSelectedLevel(newValue);
    setExerciseParams(levels[newValue]);
  };


  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", width: "97%" }}>
        <Slider
          role="tabpanel"
          value={selectedLevel}
          onChange={(event, value) => {
            handleLevelChange(value);
          }}
          min={0}
          max={levels.length-1}
        />
      </Box>
      <Typography variant="body1">
        <b>Level {selectedLevel + 1}</b>
      </Typography>
    </Box>
  );
};

export default LevelPicker;
