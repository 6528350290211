import { Box, Container, Link, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { Category } from "../../services/exercises/service";
import { useNavigate } from "react-router-dom";

/**
 * @param {{category: Category}} props
 * @return {any}
 */
const CategoryComponent = ({ category }) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Typography variant="h2" sx={{ mb: "1rem", fontSize: "2rem", fontWeight: 400 }}>
        {category.description}
      </Typography>
      {category.exercises.map((exercise, index) => {
        return (
          <div key={index}>
            <Link
              onClick={(e) => {
                e.preventDefault();
                navigate({
                  pathname: "/exercise",
                  search: `?id=${exercise.id}`,
                });
              }}
            >
              <Typography variant="h5">{exercise.name}</Typography>
            </Link>
          </div>
        );
      })}
    </Box>
  );
};

CategoryComponent.propTypes = {
  category: PropTypes.object,
};

export default CategoryComponent;
