import metronome from "../services/metronome";
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import TimeSignature from "../services/score/TimeSignature";
// @ts-ignore
import { Slider, Typography } from "@mui/material";

import withStyles from '@mui/styles/withStyles';
import InAndOut from "./controls/InAndOut";
import { useStickyState } from "./lib/stickyState";


const styles = (theme) => ({
  container: {
    backgroundColor: "white",
    color: "black",
  },
});

/**
 * @param {Number} value tempo.
 * @return {String} label corresponding to the tempo.
 */
const tempoLabel = (value) => {
  let tempoName;
  if (value <= 24) {
    tempoName = "Larghissimo";
  } else if (value <= 40) {
    tempoName = "Grave";
  } else if (value <= 45) {
    tempoName = "Largo";
  } else if (value <= 60) {
    tempoName = "Lento";
  } else if (value <= 66) {
    tempoName = "Larghetto";
  } else if (value <= 72) {
    tempoName = "Adagio";
  } else if (value <= 76) {
    tempoName = "Adagietto";
  } else if (value <= 108) {
    tempoName = "Andante";
  } else if (value <= 120) {
    tempoName = "Moderato";
  } else if (value <= 156) {
    tempoName = "Allegro";
  } else if (value <= 176) {
    tempoName = "Vivace";
  } else if (value <= 200) {
    tempoName = "Presto";
  } else {
    tempoName = "Prestissimo";
  }
  return value + " - " + tempoName;
}

const Metronome = ({ timeSignature, playing, showFullControls }) => {

  const [tempo, setTempo] = useStickyState(0, "exerciseTempo");
  const [volume, setVolume] = useStickyState(0, "metronomeVolume");
  const canvasRef = useRef(null)

  React.useEffect(() => {
    canvasRef.current.content = "";

    metronome.init(timeSignature._timeNum, canvasRef.current);
    setTempo(metronome.getTempo());
    setVolume(metronome.getVolume() * 100)

  }, []);


  /**
   *
   * @param {*} event
   * @param {Number} tempo
   */
  const handleTempoChange = (event, tempo) => {
    metronome.setTempo(tempo);
    setTempo(tempo);
  }

  /**
   *
   * @param {*} event
   * @param {Number} volume
   */
  const handleVolumeChange = (event, volume) => {
    metronome.setVolume(volume / 100);
    setVolume(volume);
  }

  /** */
  const start = () => {
    metronome.start();
  }

  /** */
  const stop = () => {
    metronome.stop();
  }

  return (
    <>
      <InAndOut showWhen={showFullControls}>
        <div>
          <Typography variant="caption">Volume: {volume.toFixed(0)}</Typography >
          <Slider
            sx={{ width: "90%" }}
            value={volume}
            onChange={handleVolumeChange}
          />
        </div>
      </InAndOut>
      <Typography variant="caption" sx={{ width: "100%" }}>Tempo: {tempoLabel(tempo)}</Typography >
      <Slider
        sx={{ py: 0 }}
        value={tempo}
        onChange={handleTempoChange}
        min={20}
        max={200}
        step={5}
        disabled={playing}

      />
      <div id="metronomeCanvasContainer" className={`control metronome-controls-main-section`}>
        <canvas ref={canvasRef} id="metronomeCanvas" width={350} height={20} />
      </div>
    </>
  );
};

Metronome.propTypes = {
  timeSignature: PropTypes.instanceOf(TimeSignature),
  classes: PropTypes.object,
  playing: PropTypes.bool,
  showFullControls: PropTypes.bool,
};

export default withStyles(styles)(Metronome);
