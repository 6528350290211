import Slide from "@mui/material/Slide";
import React from "react";

// Must have exactly one children element
const InAndOut = (props: { children: any; showWhen: boolean }) => {
  const { showWhen, children } = props;

  return (
    <Slide appear={false} direction="down" in={showWhen} unmountOnExit={true}>
      {children}
    </Slide>
  );
  // if (showWhen) {
  //   return children;
  // } else return <></>;
};

export default InAndOut;
