import { InputExercise } from "../../types/exerciseInput/exercise";
import { exercises, categories, exercisesArray } from "./service";

// TODO Move to backend

const getExerciseById = (id: string): InputExercise => {
  for (const exercise of exercisesArray as InputExercise[]) {
    if (exercise.id === id) {
      return exercise;
    }
  }

  return null;
}

export { exercises, categories, getExerciseById };
