import { Tension } from "../../types/Tension";

type TensionsType = Record<Tension, number[]>;

export const TENSIONS: TensionsType = Object.freeze({
  TONIC: [1],
  THIRD: [1, 3],
  FIFTH: [1, 5],
  TRIAD: [1, 3, 5],
  TETRAD: [1, 3, 5, 7],
});
