import React from "react";

// thanks to https://www.joshwcomeau.com/react/persisting-react-state-in-localstorage/
export const useStickyState = <Type>(
  defaultValue: Type,
  key: string
): [Type, React.Dispatch<Type>] => {
  const [value, setValue] = React.useState<Type>(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });

  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
