import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import { categories } from "../../services/exercises";
import { InputExercise } from "../../types/exerciseInput/exercise";
import { useStickyState } from "../lib/stickyState";
import Category from "./Category";
import RandomGenerator from "./randomGenerator";
import MoreInfo from "../moreInfo";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  footer: {
    backgroundColor: "black",
    width: "100%",
    color: "white",
    marginTop: "2rem",
  },
  footerLink: {
    color: "white",
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  about: {
    marginTop: "2rem",
  },
});

enum ExerciseType {
  Random,
  Browse,
}

const ExercisePicker = ({ shouldRefresh }: { shouldRefresh: boolean }) => {
  const classes = useStyles();

  const [selectedExerciseCategory, setSelectedExerciseCategory] =
    useStickyState<ExerciseType>(
      ExerciseType.Random,
      "selectedExerciseCategory"
    );

  // bugged metronome hackity fix
  if (shouldRefresh) {
    window.location.reload();
  }

  return (
    <Container className={classes.content}>
      <div>
        <Typography
          variant="h1"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            fontSize: "2.5rem",
            mb: "0",
          }}
        >
          ReadingMusic.org
        </Typography>
        <Typography
          variant="h1"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            fontSize: "0.7rem",
            mb: "0.5rem",
          }}
        >
          Learn and practice music reading using a MIDI keyboard
        </Typography>

        <Box
          sx={{
            py: "1rem",
            px: "0.5rem",
            mb: "3rem",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Box sx={{ mb: "1rem", borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={selectedExerciseCategory}
              onChange={(event, newValue) => {
                setSelectedExerciseCategory(newValue);
              }}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="Exercise tabs selector"
            >
              <Tab label="Generate Random Exercise" {...a11yProps(0)} />
              <Tab label="Browse Songs" {...a11yProps(1)} />
            </Tabs>
          </Box>

          <Box hidden={selectedExerciseCategory != ExerciseType.Random}>
            {selectedExerciseCategory == ExerciseType.Random && (
              <RandomGenerator />
            )}
          </Box>
          <Box hidden={selectedExerciseCategory != ExerciseType.Browse}>
            {selectedExerciseCategory == ExerciseType.Browse && (
              <Box sx={{ boxShadow: 2, py: "1rem", px: "0.5rem", mb: "2rem" }}>
                {categories.map((category, index) => (
                  <Category key={index} category={category} />
                ))}
              </Box>
            )}
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", mb: "1rem" }}>
          <Typography
            variant="h5"
            component="h2"
            sx={{ color: "primary.grayText" }}
          >
            More Info
          </Typography>
        </Box>

        <MoreInfo />
      </div>

      <footer className={classes.footer}>
        <a href="mailto:nlcerda@uc.cl" className={classes.footerLink}>
          feedback
        </a>
      </footer>
    </Container>
  );
};

export default ExercisePicker;
