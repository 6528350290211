import vf from "vexflow";
import fdebug from "debug";
const debug = fdebug("app:Note.js");
import { applyKeySignature } from "../keyService";
// eslint-disable-next-line no-unused-vars
import Measure from "./Measure";

/**
 * @class
 * @memberof Score
 */
export default class Note {
  // eslint-disable-next-line valid-jsdoc
  /**
   *
   * @param {import("../../types/NoteNameWithOctave").NoteNameWithOctave[]} keys Note in C4 format.
   * @param {Number} duration Divisor to get the duration
   *                            (4 for black, 2 for white);
   * @param {import("../../types/noteDuration").NoteDuration} figure when present overrides duration
   * @param {Object} options Object with tags
   * @param {Measure} measure
   */
  constructor(keys, duration, figure, options = {}, measure) {
    debug("creating note with duration " + duration);
    debug("received options " + options);
    this.keys = keys;
    this.figureDuration = "" + (figure || duration);
    /* let durationNumber = parseInt(duration.replace('r', '')); */
    this.duration = 1.0 / duration;
    this.clef = options.clef;
    this.timeSignature = options.timeSignature;
    this.keySignature = options.keySignature;
    this.options = options;
    this.keysWithAppliedSignature = keys.map((key) =>
      applyKeySignature(key, this.keySignature)
    );

    this.applyOptions();

    /** @type {vf.Flow.StaveNote} vfNote */
    this.vfNote = null;
    this.makeVfNote();

    this.measureIndex = null;
    this.measureStartingPoint = null;

    // variables that are set in the context of an exercise.
    this.liveStartingTime = null;
    this.liveEndingTime = null;

    this.startTimeout = null;
    this.preStartTimeout = null;
    this.endTimeout = null;

    this.scheduledTimeToStart = null;
    this.scheduledTimeToEnd = null;

    /**
     * @type {Measure}
     */
    this.measure = measure;
  }


  /**
   * @param {number} tempo
   * @param {number} timeNumerator
   * @param {number} timeDenominator
   * @param {number} measuresOffset measure that is considered the t=0
   * @return {number} offset in seconds of the starting point of this note, from the start of the song.
  */
  noteStartOffset(tempo, timeNumerator, timeDenominator, measuresOffset = 0) {

    const measureDuration = (60 / tempo) * timeNumerator;
    const timeReason = timeNumerator / timeDenominator;

    const secondsOffset = (this.measureIndex - measuresOffset) * measureDuration +
      (this.measureStartingPoint / timeReason) * measureDuration;

    return secondsOffset;
  }

  /**
   * add dot accidental to note.
   */
  addDot() {
    debug("adding dot");
    this.figureDuration += "d";
    this.duration = this.duration * 1.5;
  }

  /** turns this note into a rest */
  makeRest() {
    this.figureDuration += "r";
  }

  /** */
  applyOptions() {
    debug("applying options");
    debug(this.options);
    if (this.options == null) return;
    if (this.options.dotted) this.addDot();
    if (this.options.rest) this.makeRest();
  }

  /**
   * Adds accidentals to a note, depending on it's 'keys' String value, so
   * they don't have to be declared specifically.
   */
  addAccidentals() {
    let staveNote = this.vfNote;

    if (this.options.dotted) {
      this.vfNote.addDotToAll();
    }

    for (let i = 0; i < staveNote.getKeys().length; i++) {
      let accidental = null;
      if (staveNote.getKeys()[i].includes("##")) accidental = "##";
      else if (staveNote.getKeys()[i].includes("#")) accidental = "#";
      else if (staveNote.getKeys()[i].includes("bb")) accidental = "bb";
      else if (staveNote.getKeys()[i].includes("b")) accidental = "b";
      else if (staveNote.getKeys()[i].includes("n")) accidental = "n";
      if (accidental != null) {
        staveNote.addAccidental(i, new vf.Flow.Accidental(accidental));
      }
    }
  }

  /**
   * makes it's corresponding staveNote and apply options.
   */
  makeVfNote() {
    this.vfNote = new vf.Flow.StaveNote({
      clef: this.clef,
      keys: this.keys,
      duration: this.figureDuration,
    });

    this.addAccidentals();
  }

  /**
   *
   * @param {String} color css color.
   */
  paint(color) {
    this.vfNote.setStyle({ fillStyle: color });
    this.vfNote.draw();
  }
}
