import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import ConnectYourKeyboardModal from "./connectYourKeyboard";

const MoreInfo = () => {
  const [keyboardInstructionsOpen, setKeyboardInstructionsOpen] =
    useState(false);
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item md={4} xs={12}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              ></Typography>
              <Typography variant="h5" component="div">
                How to connect my keyboard?
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
              <Typography variant="body2">
                To get the most out of this app, you need to connect any kind
                digital keyboard.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  setKeyboardInstructionsOpen(true);
                }}
              >
                Learn how
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <ConnectYourKeyboardModal
        open={keyboardInstructionsOpen}
        setOpen={setKeyboardInstructionsOpen}
      />
    </>
  );
};

export default MoreInfo;
