"use strict";
import metronome from "../metronome";
// @ts-ignore somehow editor says it can't find it, but it can.
import Soundfont from "soundfont-player";

import ExerciseNotes from "../score/ExerciseNotes"; // eslint-disable-line
import Note from "../score/Note"; // eslint-disable-line
import { applyKeySignature } from "../keyService";

import fdebug from "debug";

const debug = fdebug("app:midiPlayer");

let notesQueue = [];
let piano;
/**
 * @type {ExerciseNotes}
 */
let curExNotes = null;
let onEndTimeout = { ref: null };

/**
 * Loads an instrument to play, this operation takes time, so it shouldn't be
 * called if by doing so it affects the timing.
 * @param {AudioContext} audioContext in which the instrument will play.
 * @param {function} cb callback
 */
async function loadInstrument(audioContext, cb) {
  try {
    debug("starting to load instrument");
    piano = await Soundfont.instrument(audioContext, "acoustic_grand_piano", {
      gain: 2,
    });
    debug("ended loading instrument");
    cb();
  } catch (e) {
    console.error(e);
  }
}

/**
 * 
 * @param {String} key 
 */
function playNote(key) {
  if (piano == null)
    throw new Error("instrument not loaded")

  piano.play(key, 0, 0);
}

/** */
function stop() {
  debug("Stopping player");
  debug(onEndTimeout);
  clearTimeout(onEndTimeout.ref);
  while (notesQueue.length > 0) {
    let note = notesQueue.shift();
    clearTimeout(note.preStartTimeout);
    clearTimeout(note.startTimeout);
    clearTimeout(note.endTimeout);
  }

  piano.stop();

  if (!(curExNotes == null)) {
    for (let m = 0; m < curExNotes.measures.length; m++) {
      curExNotes.measures[m].staves.forEach((stave) => {
        stave.voices.forEach((voice) => {
          voice.notes.forEach((note) => {
            note.paint("black");
          });
        });
      });
    }
  }
}

/**
 *
 * @param {{exNotes: ExerciseNotes, timeToStart: Number, leftHand: Boolean, rightHand: Boolean, barFrom: Number, barTo: Number, paintNotes: Boolean, onEnd: Function}} exNotes
 */
function playNotes({
  exNotes,
  timeToStart,
  leftHand = true,
  rightHand = true,
  barFrom,
  barTo,
  paintNotes,
  onEnd,
}) {
  curExNotes = exNotes;
  let audioContext = metronome.getAudioContext();
  debug("starting to playnotes");
  let tempo = metronome.tempo;
  let measureSize = metronome.getTimesPerMeasure();
  const timeReason = curExNotes.timeNumerator / curExNotes.timeDenominator;
  let measureDuration = (60 / tempo) * measureSize;
  const firstMeasure = barFrom != null && barFrom >= 0 ? barFrom : 0;
  const lastMeasure =
    barTo != null && barTo >= barFrom && barTo < exNotes.measures.length
      ? barTo
      : exNotes.measures.length - 1;
  let playedMeasures = 0;
  let scheduledEnd = false;
  for (let m = firstMeasure; m <= lastMeasure; m++, playedMeasures++) {
    exNotes.measures[m].staves.forEach((stave, staveIndex) => {
      stave.voices.forEach((voice, voiceIndex) => {
        if (staveIndex === 0 && !rightHand) return;
        if (staveIndex === 1 && !leftHand) return;
        voice.notes.forEach((note, noteIndex) => {
          const startOffset =
            playedMeasures * measureDuration +
            (note.measureStartingPoint / timeReason) * measureDuration;
          const endOffset =
            playedMeasures * measureDuration +
            ((note.measureStartingPoint + note.duration) / timeReason) *
            measureDuration;

          let start = timeToStart + startOffset;
          let end = timeToStart + endOffset;

          debug(
            `EDBG: Playing note ${noteIndex}/${voice.notes.length - 1
            } of measure ${m}/${lastMeasure}`
          );
          if (
            noteIndex == voice.notes.length - 1 &&
            m == lastMeasure &&
            !scheduledEnd
          ) {
            debug(
              `EDBG: setting timeout for ${(end - audioContext.currentTime) * 1000
              }`
            );
            onEndTimeout.ref = setTimeout(() => {
              debug("Calling onEnd callback");
              onEnd();
            }, (end - audioContext.currentTime) * 1000);
            scheduledEnd = true;
          }

          debug("start: " + start + ", end: " + end);
          doubleTimeoutSchedule(note, start, end, audioContext, paintNotes);
          note.scheduledTimeToStart = start;
          note.scheduledTimeToEnd = end;

          if (note.figureDuration.includes("r")) {
            debug("silence at : " + start + " - " + note.measureStartingPoint);
            return;
          }
          note.keys.forEach((key) => {
            piano.connect(audioContext.destination);
            piano.play(
              applyKeySignature(key, note.keySignature).replace("/", ""),
              start,
              end - start
            );
          });
        });
      });
    });
  }
}

/**
 *
 * @param {Note} note
 * @param {Number} timeToStart
 * @param {Number} timeToEnd
 * @param {AudioContext} audioContext
 * @param {Boolean} paintNotes
 */
function doubleTimeoutSchedule(
  note,
  timeToStart,
  timeToEnd,
  audioContext,
  paintNotes
) {
  notesQueue.push(note);
  note.liveStartingTime = timeToStart;
  note.liveEndingTime = timeToEnd;
  note.preStartTimeout = setTimeout(() => {
    noteWillStart(note);
    note.startTimeout = setTimeout(() => {
      noteStart(note, paintNotes);
    }, (timeToStart - audioContext.currentTime) * 1000 - 2);

    note.endTimeout = setTimeout(() => {
      noteEnd(note, paintNotes);
    }, (timeToEnd - audioContext.currentTime) * 1000);
  }, (timeToStart - audioContext.currentTime) * 1000 - 100);
}

/** function that is called 100 ms before a note starts. idk if iwui
 * @param {Note} note that is starting start.
 */
function noteWillStart(note) { }

/** function that is called when a note starts.
 * @param {Note} note that is starting
 * @param {Boolean} paintNotes
 */
function noteStart(note, paintNotes) {
  debug("note " + note.keys + " started");
  debug(note);
  if (paintNotes) {
    note.paint("red");
  }
}

/**
 * Function that get's called by the player when a note ends. It's called by
 * a timer set by the scheduler.
 * @param {Note} note Note that ends at the time.
 * @param {Boolean} paintNotes
 */
function noteEnd(note, paintNotes) {
  if (paintNotes) {
    note.paint("gray");
  }
  debug("note " + note.keys + " ended ");
}

export default {
  playNotes,
  stop,
  loadInstrument,
  playNote,
};
