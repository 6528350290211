import PlayArrow from "@mui/icons-material/PlayArrow";
import Stop from "@mui/icons-material/Stop";
import { IconButton } from "@mui/material";

import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import InAndOut from "./InAndOut";

export const ScorePlayer = ({
  playing,
  playLeftHand,
  setPlayLeftHand,
  playRightHand,
  setPlayRightHand,
  paintNotes,
  setPaintNotes,
  metronomeGracePeriod,
  setMetronomeGracePeriod,
  loop,
  setLoop,
  readyToPlay,
  playExercise,
  stopPlayingExcercise,
  startChallengeMode,
  showFullControls,
  optionsDisabled,
}) => {
  const isDesktop = window.innerWidth > 850;

  return (
    <Fragment>
      <InAndOut showWhen={showFullControls}>
        <div>
          <FormGroup>
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={paintNotes}
                  disabled={optionsDisabled}
                  onChange={(event) => {
                    setPaintNotes(event.target.checked);
                  }}
                />
              }
              label="Paint notes"
            /> */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={metronomeGracePeriod}
                  disabled={optionsDisabled}
                  onChange={(event) => {
                    setMetronomeGracePeriod(event.target.checked);
                  }}
                />
              }
              label="Count In"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={loop}
                  disabled={optionsDisabled}
                  onChange={(event) => {
                    setLoop(event.target.checked);
                  }}
                />
              }
              label="Loop"
            />
          </FormGroup>
        </div>
      </InAndOut>
      <div className="controls-main-section">
        {!playing && readyToPlay && (
          <IconButton
            aria-label="Play score"
            disabled={!readyToPlay || optionsDisabled}
            onClick={playExercise}
            size="large"
          >
            <PlayArrow fontSize="large" />
          </IconButton>
        )}

        {playing && (
          <IconButton
            aria-label="Stop"
            disabled={!optionsDisabled}
            onClick={stopPlayingExcercise}
            size="large"
          >
            <Stop fontSize="large" />
          </IconButton>
        )}
      </div>
    </Fragment>
  );
};

ScorePlayer.propTypes = {
  loop: PropTypes.any,
  metronomeGracePeriod: PropTypes.any,
  paintNotes: PropTypes.any,
  playExercise: PropTypes.any,
  playLeftHand: PropTypes.any,
  playRightHand: PropTypes.any,
  playing: PropTypes.any,
  readyToPlay: PropTypes.any,
  setLoop: PropTypes.func,
  setMetronomeGracePeriod: PropTypes.func,
  setPaintNotes: PropTypes.func,
  setPlayLeftHand: PropTypes.func,
  setPlayRightHand: PropTypes.func,
  stopPlayingExcercise: PropTypes.any,
  startChallengeMode: PropTypes.func,
  showFullControls: PropTypes.bool,
  optionsDisabled: PropTypes.bool,
};
