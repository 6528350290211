import { createRythmElement, createRythmElementWithDefaults } from "../components/lib/rythm";
import { Rythm } from "../types/Rythm";
import { NoteDuration, NoteDurations } from "../types/noteDuration";

const basicDurationsMap = {
    "whole": {duration: NoteDurations.Whole, name: "Whole Note" },
    "half": {duration: NoteDurations.Half, name: "Half Note"},
    "quarter": {duration: NoteDurations.Quarter, name: "Quarter Note"},
    "eight": {duration: NoteDurations.Eight, name: "Eight Note"},
    "sixteenth": {duration: NoteDurations.Sixteenth, name: "Sixteenth Note"},
}

const simpleSingleNoteRythms: Rythm[] = Object.keys(basicDurationsMap).map(id => ({
    name: basicDurationsMap[id].name,
    id: id,
    elements: [
        createRythmElement(basicDurationsMap[id].duration)
    ]
}))

const syncopations = [{
    name: "Syncopation",
    id: "dotted-quarter-and-eight",
    elements: [
        createRythmElementWithDefaults({ duration: NoteDurations.Quarter, dotted: true }),
        createRythmElementWithDefaults({ duration: NoteDurations.Eight })
    ]
},{
    name: "Syncopation",
    id: "dotted-eight-and-sixteenth",
    elements: [
        createRythmElementWithDefaults({ duration: NoteDurations.Eight, dotted: true }),
        createRythmElementWithDefaults({ duration: NoteDurations.Sixteenth })
    ]
}, ]

export const DEFAULT_RTYHMS = [...simpleSingleNoteRythms, ...syncopations]

export const getRythmById = (id: string) : Rythm => {
    return DEFAULT_RTYHMS.find((rythm) => rythm.id == id)
}