import React from 'react';

import { Paper } from '@mui/material';

// eslint-disable-next-line require-jsdoc
function ScoreComponent(props) {
  return (
    <>
      <Paper id='visibleScore'>
        <div id='scoreContainer' />
      </Paper>
    </>
  );
}

export default ScoreComponent;
