import WebMidi, { Input, InputEventNoteon } from "webmidi";
import fdebug from "debug";
import PlayableNode from "./PlayableNode";
const debug = fdebug("app:IO:MidiService");

let selectedDeviceIndex = 0;

const getEnabledWebMidi = async (): Promise<typeof WebMidi> => {
  return new Promise((resolve, reject) => {
    if (WebMidi.enabled) {
      resolve(WebMidi);
    } else {
      WebMidi.enable((err) => {
        if (err) {
          reject("Error enabling WebMidi " + err);
        } else {
          resolve(WebMidi);
        }
      });
    }
  });
};

/**
 *
 * @return {Promise<Input[]>}
 */
const getMidiDevices = async (): Promise<Input[]> => {
  return (await getEnabledWebMidi()).inputs;
};

/**
 *
 * @return {Promise<Input>}
 */
const getSelectedMidiDevice = async (): Promise<Input> => {
  const midiDevices = await getMidiDevices();
  console.log("Getting device to read events from, index: ", selectedDeviceIndex)
  if (midiDevices == null || midiDevices.length == 0) return null;
  return midiDevices[selectedDeviceIndex];
};

/**
 * @return {number}
 */
const getSelectedDeviceIndex = (): number => {
  return selectedDeviceIndex;
};

const setSelectedDeviceIndex = (index: number): void => {
  selectedDeviceIndex = index;
};

const getNextKeyPress: (
  callback: (inputEventNoteOn: InputEventNoteon) => void
) => Promise<Input> = async (callback) => {
  const midiDevices = await getMidiDevices();

  if (midiDevices == null || midiDevices.length == 0) return null;

  const device = midiDevices[0];

  device.addListener("noteon", "all", (noteOnEvent: InputEventNoteon) => {
    debug("Handling note once");
    callback(noteOnEvent);
    device.removeListener("noteon", "all");
  });

  return device;
};

/**
 *
 */
const setDefaultDevice = async () => {
  const inputs = await getMidiDevices();
  if (inputs == null || inputs.length < 1) {
    setSelectedDeviceIndex(null);
  } else {
    setSelectedDeviceIndex(0);
  }
};

/**
 * Function that takes a callback and calls it each time there are changes to connected
 * midi devices. Note that each time midi devices change, the selected device index is set to 0
 */
const onMidiDevicesChange = async (
  callback: (inputs: Input[], selectedDeviceIndex: number) => void
) => {
  const WebMidiInstance = await getEnabledWebMidi();

  WebMidiInstance.addListener("connected", async () => {
    debug("New midi device connection");
    const midiDevices = await getMidiDevices();
    setDefaultDevice();
    callback(midiDevices, getSelectedDeviceIndex());
  });

  WebMidiInstance.addListener("disconnected", async () => {
    debug("New midi device disconnection");
    const midiDevices = await getMidiDevices();
    setDefaultDevice();
    callback(midiDevices, getSelectedDeviceIndex());
  });
};

export {
  setDefaultDevice,
  getSelectedDeviceIndex,
  setSelectedDeviceIndex,
  onMidiDevicesChange,
  getSelectedMidiDevice,
  getMidiDevices,
  getNextKeyPress,
};
