"use-strict";
import fdebug from "debug";
import ScoreBuilder from "./ScoreBuilder";
import ExerciseNotes from "./ExerciseNotes"; // eslint-disable-line
import Measure from "./Measure"; // eslint-disable-line
import { scrollToPosition } from "./scrolling";

const debug = fdebug("app:Score");

/**
 *
 * ScoreBuilder
 * This class is in charge of taking a format and print it in a vexflow
 * easyScore
 * @class
 */
export default class Score {
  /**
   *
   * @param {String} divId Id of the div where the score will be displayed.
   */
  constructor(divId = "scoreContainer") {
    // scorebuilder related vars
    this.scoreBuilder = new ScoreBuilder(divId);
    this.exerciseNotes = null;

    // vars you should pass to builder
    this._timeNum = 4;
    this._timeDen = 4;

    // vars belonging to this module.
    this.timeMarks = [];

    this.currentBeat = 0;
    this.startPlayingAt = 0;
    this.playFromBar = null;
    this.playToBar = null;
  }

  /**
   *
   * @param {Object} exercise Format defined in src/exercises.
   * @return {ExerciseNotes}
   */
  addExercise(exercise) {
    debug("drawing score");
    debugger
    const exNotes = this.scoreBuilder.drawScore(exercise);
    this.exerciseNotes = exNotes;

    this._makeTimeMarks();
    return exNotes;
  }

  /**
   * time setter
   * @param {String} time time in 4/4 format.
   */
  set time(time) {
    this.scoreBuilder.time = time;
    [this._timeNum, this._timeDen] = time
      .split("/")
      .map((time) => parseInt(time));
  }

  /**
   * time getter
   */
  get time() {
    return this._timeNum + "/" + this._timeDen;
  }

  /**
   * @param {Number} beat beat number to start playing at;
   * @param {Number} barFrom sets the beat at where it should start;
   * @param {Number} barTo sets the beat at where it should start;
   */
  setStartPlayingAt(beat = 0, barFrom, barTo) {
    this.startPlayingAt = beat;
    this.playFromBar = barFrom || 0;
    this.playToBar = barTo || 0;
  }

  /**
   *
   * @param {Number} currentBeat current beat
   */
  handleTick(currentBeat) {
    this.currentBeat = currentBeat;
    if (this.currentBeat < this.startPlayingAt) return;

    let timeMarkIndex =
      this.currentBeat -
      this.startPlayingAt +
      ((this.playFromBar * this.exerciseNotes.timeNumerator) %
        this.timeMarks.length);

    // debug("handling tick " + timeMarkIndex + "(" + this.currentBeat + ")");

    if (timeMarkIndex === 0) {
      this.resetTimemarks();
    }

    if (timeMarkIndex >= this.timeMarks.length)
      return;

    this._drawTimeMark(timeMarkIndex, "darkBlue");
    // Scrolling
    let nextScrollPosition =
      this.timeMarks[timeMarkIndex].tickable.stave.bounds.y - 10;

    scrollToPosition(nextScrollPosition);
    // 
  }

  /** */
  resetTimemarks() {
    for (let i = 0; i < this.timeMarks.length; i++) {
      this._drawTimeMark(i, "deepSkyBlue");
    }
  }

  /**
   * draws a timemark for a data declared in the this.timeMarks array.
   * @param {Number} timeMarkIndex
   * @param {String} color
   */
  _drawTimeMark(timeMarkIndex, color = "deepSkyBlue") {
    // debug("drawing timeMark: " + timeMarkIndex);
    let tmData = this.timeMarks[timeMarkIndex];
    let y = tmData.tickable.stave.bounds.y;

    let x =
      tmData.tickable.note_heads[0].x +
      tmData.offset * tmData.tickable.formatterMetrics.space.used;
    if (tmData.tickable.formatterMetrics.space.used === 0) {
      x =
        tmData.tickable.note_heads[0].x +
        tmData.offset * tmData.tickable.stave.width;
    }
    let size = 10;
    let tm = this.scoreBuilder.context
      .beginPath()
      .moveTo(x, y)
      .lineTo(x + size / 2, y + size / 8)
      .lineTo(x + size, y)
      // @ts-ignore
      .quadraticCurveTo(x + size / 1.7, y + size / 1.7, x + size / 2, y + size)
      .quadraticCurveTo(x + size / 2, y + size / 2, x, y)
      .closePath()
      .setFillStyle(color)
      .fill();
  }

  /** */
  _makeTimeMarks() {
    const exNotes = this.scoreBuilder.exerciseNotes;
    for (let measure = 0; measure < exNotes.measures.length; measure++) {
      for (let i = 0; i < this._timeNum; i++) {
        // debug("getting timemark of index " + i);
        let tmData = this._getTimemarkData(exNotes.measures[measure], i);
        // debug(
        //   `making time mark ${i} in ${tmData.index}th tickable ` +
        //   `${tmData.offset}`
        // );
        // debug(tmData);
        this.timeMarks.push(tmData);
        this._drawTimeMark(this.timeMarks.length - 1);
      }
    }
    this.attachToMetronome();
  }

  /**
   * Attaches to the metronome.
   */
  attachToMetronome() {
    window.addEventListener(
      "metronomeTick",
      this.metronomeTickHandler.bind(this),
      false
    );
  }

  /**
   * @function
   * @param {CustomEvent} event
   */
  metronomeTickHandler(event) {
    this.handleTick(event.detail.currentBeat);
  }

  /**
   *
   * return the tickable corresponding to the timeIndexth beat of its measure.
   * returns null if the
   * @param {Measure} measure
   * @param {Number} timeIndex
   * @return {Object} tickable
   */
  _getTimemarkData(measure, timeIndex) {
    const voice = measure.staves[0].voices[0];
    const vfvoice = voice.vfVoice;

    debug(
      "getting timemark data for time index " +
      timeIndex +
      ", measure: " +
      measure.index
    );
    let sum = 0;
    let val = timeIndex / this._timeDen; // goes from 0 to 4/4 (int 4/4)
    if (timeIndex === 0) {
      return { tickable: vfvoice.getTickables()[0], offset: 0, index: 0 };
    }
    for (let i = 0; i < vfvoice.getTickables().length; i++) {
      sum += voice.notes[i].duration;
      debug("sum: " + sum + " val " + val);
      if (sum < val) continue;
      else if (sum === val) {
        return i < vfvoice.getTickables().length - 1
          ? { offset: 0, tickable: vfvoice.getTickables()[i + 1], index: i + 1 }
          : null;
      } else {
        debug("passed sum: " + sum + " val " + val);
        // for a white in 4/4 for time 1: sum-val = 1/2
        return {
          offset:
            (val - (sum - voice.notes[i].duration)) / voice.notes[i].duration,
          tickable: vfvoice.getTickables()[i],
          index: i,
        };
      }
    }
  }
}
