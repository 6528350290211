import { IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { Dispatch, Fragment, SetStateAction } from "react";
import UpIcon from "@mui/icons-material/ArrowCircleUp";
import DownIcon from "@mui/icons-material/ArrowCircleDown";

interface FullControlsTogglerProps {
  showFullControls: boolean;
  setShowFullControls: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles({
  mirrored: {
    transform: "scaleX(-1)",
  },
});

const FullControlsToggler = ({
  showFullControls,
  setShowFullControls,
}: FullControlsTogglerProps) => {
  const classes = useStyles();

  const onClick = () => {
    setShowFullControls(!showFullControls);
  };

  return (
    <div>
      {showFullControls ? (
        <IconButton onClick={onClick} className="text-icon-button">
          <UpIcon />
          <Typography variant="button" sx={{ fontSize: "0.65rem" }}>
            Less Opts
          </Typography>
        </IconButton>
      ) : (
        <IconButton onClick={onClick} className="text-icon-button">
          <DownIcon />
          <Typography variant="button" sx={{ fontSize: "0.65rem" }}>
            More Opts
          </Typography>
        </IconButton>
      )}
    </div>
  );
};

export default FullControlsToggler;
