import Note from './Note';
import fdebug from 'debug';
const debug = fdebug('app:scrolling');

/**
 * 
 * @param {Note} note 
 */
const scrollToNote = (note) => {
  const div = document.getElementById("visibleScore");
  const noteY = note.measure.staves[0].vfStave.getYForTopText();
  debug("Scrolling to note", note, noteY);
  div.scroll({ top: noteY - 40, behavior: 'smooth' });
}

const scrollToPosition = (top) => {
  const div = document.getElementById("visibleScore");
  div.scroll({ top: top, behavior: 'smooth' });
}

export { scrollToNote, scrollToPosition }
