// @ts-nocheck
/**
 * @type {import("../../../types/exerciseInput/exercise").InputExercise}
 */
const exercise = {
  name: "Chopin's Nocturne No 20",
  id: "examples~chopinNocturne20",
  config: {
    time: "4/4",
    keySignature: "E",
    clefs: ["treble", "bass"],
  },
  measures: [
    {
      staves: [
        {
          voices: [
            {
              notes: [
                { keys: ["E/4", "G/4", "C/5"], duration: 4 },
                { keys: ["B/4"], duration: 8, options: { rest: true } },
                {
                  keys: ["C/4", "G/4", "B/4"],
                  duration: 8,
                  options: { tieStart: [0, 2] },
                },
                {
                  keys: ["C/4", "F/4", "A/4"],
                  duration: 4,
                  options: { tieEnd: [0, 2] },
                },
                { keys: ["C/4"], duration: 8, options: { rest: true } },
                {
                  keys: ["C/4", "E/4", "G/4"],
                  duration: 8,
                  options: { tieStart: [0, 2] },
                },
              ],
            },
          ],
        },
        {
          voices: [
            {
              notes: [
                { keys: ["C/2"], duration: 4 },
                /* r */ { keys: ["B/3"], duration: 8, options: { rest: true } },
                { keys: ["C/3"], duration: 8 },
                { keys: ["F/3"], duration: 4 },
                /* r */ { keys: ["B/3"], duration: 8, options: { rest: true } },
                { keys: ["C/3"], duration: 8 },
              ],
            },
          ],
        },
      ],
    },
    {
      staves: [
        {
          voices: [
            {
              notes: [
                {
                  keys: ["C/4", "E/4", "F##/4"],
                  duration: 4,
                  options: { tieEnd: [0, 2] },
                },
                { keys: ["B#/3", "D/4", "G/4"], duration: 4 },
                { keys: ["B/4"], duration: 2, options: { rest: true } },
              ],
            },
          ],
        },
        {
          voices: [
            {
              notes: [
                { keys: ["A/2"], duration: 4 },
                { keys: ["G/2"], duration: 4 },
                { keys: ["D/3"], duration: 2, options: { rest: true } },
              ],
            },
          ],
        },
      ],
    },
    {
      staves: [
        {
          voices: [
            {
              notes: [
                { keys: ["E/4", "G/4", "C/5"], duration: 4 },
                { keys: ["B/4"], duration: 8, options: { rest: true } },
                { keys: ["C/4", "G/4", "B/4"], duration: 8 },
                { keys: ["C/4", "F/4", "A/4"], duration: 4 },
                { keys: ["C/4"], duration: 8, options: { rest: true } },
                { keys: ["C/4", "E/4", "G/4"], duration: 8 },
              ],
            },
          ],
        },
        {
          voices: [
            {
              notes: [
                { keys: ["C/2"], duration: 4 },
                /* r */ { keys: ["B/3"], duration: 8, options: { rest: true } },
                { keys: ["C/3"], duration: 8 },
                { keys: ["F/3"], duration: 4 },
                /* r */ { keys: ["B/3"], duration: 8, options: { rest: true } },
                { keys: ["C/3"], duration: 8 },
              ],
            },
          ],
        },
      ],
    },
    {
      staves: [
        {
          voices: [
            {
              notes: [
                { keys: ["C/4", "E/4", "F##/4"], duration: 4 },
                { keys: ["B#/3", "D/4", "G/4"], duration: 4 },
                { keys: ["B/4"], duration: 2, options: { rest: true } },
              ],
            },
          ],
        },
        {
          voices: [
            {
              notes: [
                { keys: ["A/2"], duration: 4 },
                { keys: ["G/2"], duration: 4 },
                { keys: ["D/3"], duration: 2, options: { rest: true } },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default exercise;
