import fdebug from "debug";
import { NoteName } from "../../types/NoteName";
import { MAJOR_KEYS } from "../../types/MajorKeys";
import { Octave } from "../../types/Octave";
import { UNALTERED_NOTES } from "../../types/UnalteredNotes";
import { NoteNameWithOctave } from "../../types/NoteNameWithOctave";
import { UnalteredNoteName } from "../../types/UnalteredNoteName";
import { UnalteredNoteWithOctave } from "../../types/UnalteredNoteWithOctave";
import { MajorKey } from "../../types/MajorKey";
const debug = fdebug("app:keyService");

const notesList: UNALTERED_NOTES[] = [
  UNALTERED_NOTES.C,
  UNALTERED_NOTES.D,
  UNALTERED_NOTES.E,
  UNALTERED_NOTES.F,
  UNALTERED_NOTES.G,
  UNALTERED_NOTES.A,
  UNALTERED_NOTES.B,
];

const KEY_SIGNATURES = {
  Cb: ["B", "E", "A", "D", "G", "C", "F", "b"],
  Gb: ["B", "E", "A", "D", "G", "C", "b"],
  Db: ["B", "E", "A", "D", "G", "b"],
  Ab: ["B", "E", "A", "D", "b"],
  Eb: ["B", "E", "A", "b"],
  Bb: ["B", "E", "b"],
  F: ["B", "b"],
  C: [],
  G: ["F", "#"],
  D: ["F", "C", "#"],
  A: ["F", "C", "G", "#"],
  E: ["F", "C", "G", "D", "#"],
  B: ["F", "C", "G", "D", "A", "#"],
  "F#": ["F", "C", "G", "D", "A", "E", "#"],
  "C#": ["F", "C", "G", "D", "A", "E", "B", "#"],
};

const splitNoteAndOctave = (
  note: NoteNameWithOctave
): [UnalteredNoteName, Octave] => {
  const [noteName, octaveStr] = note.split("/");
  return [noteName as UnalteredNoteName, parseInt(octaveStr) as Octave];
};

const mergeNoteAndOctave = (
  note: UnalteredNoteName,
  octave: Octave
): NoteNameWithOctave => {
  return `${note}/${octave}`;
};

/**
 *
 * @param tone Key
 * @param degree
 * @returns unaltered note representing the given degree
 */
const getNoteForKeyAndDegree: (
  tone: MajorKey,
  degree: number
) => UNALTERED_NOTES = (tone, degree) => {
  const firstDegree: UNALTERED_NOTES = removeAlterations(tone);
  const firstDegreeIndex: number = notesList.indexOf(firstDegree);

  const targetDegreeIndex: number =
    (firstDegreeIndex + (degree - 1)) % notesList.length;

  return notesList[targetDegreeIndex];
};

/**
 *
 * @param {UnalteredNoteName | UnalteredNoteWithOctave} key
 * @param {MAJOR_KEYS} keySig
 * @return {NoteName | NoteNameWithOctave} returns a string corresponding to the note with the
 * key signature applied.
 */
const applyKeySignature = (
  key:
    | UnalteredNoteName
    | UnalteredNoteWithOctave
    | NoteNameWithOctave
    | NoteName,
  keySig: MAJOR_KEYS
): NoteName | NoteNameWithOctave => {
  debug("Applying key signature", key, keySig);
  let keynote = key.substr(0, 1);
  const keySigAlteration =
    KEY_SIGNATURES[keySig][KEY_SIGNATURES[keySig].length - 1];
  let [onlyKey, octave] = key.split("/");

  let returnValue = "";
  if (
    keySig == null ||
    KEY_SIGNATURES[keySig].indexOf(keynote) < 0 ||
    onlyKey.includes("#") ||
    onlyKey.includes("b") ||
    onlyKey.includes("n")
  ) {
    debug(keynote + " not in " + KEY_SIGNATURES[keySig]);
    returnValue = key;
    if (onlyKey.includes("n")) returnValue = returnValue.replace("n", "");
  } else if (KEY_SIGNATURES[keySig].indexOf(keynote) >= 0) {
    returnValue =
      onlyKey + keySigAlteration + (octave != null ? "/" + octave : "");
  }
  debug("returning " + returnValue);
  return returnValue as NoteName | NoteNameWithOctave;
};

/**
 * @param {NoteName} key key (C, Cb A#...)
 * @param {number} octave
 * @return {number}
 */
const getMidiNumber = (key: NoteName, octave: number): number => {
  return noteOffsets[key] + 12 + 12 * octave;
};

const getAllNotesWithoutAlterations = (
  fromOctave: number = 0
): UnalteredNoteWithOctave[] => {
  const result = [];

  for (let i = fromOctave; i <= 8; i++) {
    notesList.forEach((note) => {
      result.push(`${note}/${i}`);
    });
  }
  return result;
};

const noteOffsets = {
  C: 0,
  "C#": 1,
  "C##": 2,
  Dbb: 0,
  Db: 1,
  D: 2,
  "D#": 3,
  "D##": 4,
  Ebb: 2,
  Eb: 3,
  E: 4,
  "E#": 5,
  "E##": 6,
  Fbb: 3,
  Fb: 4,
  F: 5,
  "F#": 6,
  "F##": 7,
  Gbb: 5,
  Gb: 6,
  G: 7,
  "G#": 8,
  "G##": 9,
  Abb: 7,
  Ab: 8,
  A: 9,
  "A#": 10,
  "A##": 11,
  Bbb: 9,
  Bb: 10,
  B: 11,
  Cbb: 10,
  Cb: 11,
  "B#": 12,
  "B##": 13,
};

/**
 *
 * @param {string} note note in C#/4 format
 * @return {UNALTERED_NOTES}
 */
const removeAlterations = (note: string): UNALTERED_NOTES => {
  return note.replace(/#|b/, "") as UNALTERED_NOTES;
};

const distance = (noteFrom: NoteNameWithOctave, noteTo: NoteNameWithOctave) => {
  const [keyFrom, octaveFrom] = noteFrom.split("/");
  const [keyTo, octaveTo] = noteTo.split("/");
  return Math.abs(
    getMidiNumber(keyFrom as NoteName, parseInt(octaveFrom)) -
    getMidiNumber(keyTo as NoteName, parseInt(octaveTo))
  );
};

export {
  splitNoteAndOctave,
  mergeNoteAndOctave,
  removeAlterations,
  KEY_SIGNATURES,
  getNoteForKeyAndDegree,
  getMidiNumber,
  getAllNotesWithoutAlterations,
  applyKeySignature,
  distance,
};
