import React from "react";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { Box, IconButton, Typography, Modal } from "@mui/material";
import PlayArrow from "@mui/icons-material/PlayArrow";
import Straighten from "@mui/icons-material/Straighten";
import MusicNote from "@mui/icons-material/MusicNote";

import UpIcon from "@mui/icons-material/ArrowCircleUp";
import PanTool from "@mui/icons-material/PanTool";

const Instructions = () => {
  const [open, setOpen] = React.useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <IconButton
        sx={{ displat: "flex", flexDirection: "column" }}
        onClick={openModal}
      >
        <HelpCenterIcon />
        {/* <Typography variant="button" sx={{ fontSize: "0.65rem" }}>
          Instructions
        </Typography> */}
      </IconButton>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h4">How to use this tool?</Typography>
          <p>
            <Typography variant="caption">
              ReadingMusic.org is a site to learn and practice sight reading. Is
              muuch better if you connect a midi keyboard!
            </Typography>
          </p>
          <p>
            <Typography variant="caption">
              <PlayArrow />: to listen to the exercise, you can use the hand
              icons.
            </Typography>
          </p>
          <p>
            <Typography variant="caption">
              <Straighten />: The app waits for you to play the notes, just play
              the blue note.
            </Typography>
          </p>
          <p>
            <Typography variant="caption">
              <MusicNote />: Play the right note, at the right time. Sound
              appears when you press a wrong key. played.
            </Typography>
          </p>
          <p>
            <Typography variant="caption">
              <PanTool />: Choose if you want to play one or both hands.
            </Typography>
          </p>
          <p>
            <Typography variant="caption">
              Click on the <UpIcon /> to see more options!
            </Typography>
          </p>
          <p>
            <Typography variant="h6">Enjoy and learn!</Typography>
          </p>{" "}
        </Box>
      </Modal>
    </div>
  );
};

export default Instructions;
