import Webmidi, { InputEventNoteon } from "webmidi";
import metronome from "../metronome";
import ExerciseNotes from "../score/ExerciseNotes";
import { getSelectedMidiDevice } from "./midiService";
import PlayableNode from "./PlayableNode";
import {
  buildExpectedNotesPath,
  getSelectedPlaybackOption,
} from "./midiListener";
import fdebug from "debug";
import { paintNotes, resetPaintedNotes } from "../context";
import { PlaybackOption } from "../../types/PlaybackOption";
import midiPlayer from "./player";

const debug = fdebug("app:challengeMode");

const ERROR_MARGIN: number = 140; // ms
let currentNode: PlayableNode = null;
let challengeStartingTime: number = 0;
let midiStartingTime: number = 0;
let audioContext: AudioContext = null;

let oscillator = null;

let playing: boolean = false;

const startChallengeMode = async ({
  timeToStart,
  exerciseNotes,
  fromBar,
  toBar,
  leftHand,
  rightHand,
}: {
  timeToStart: number;
  exerciseNotes: ExerciseNotes;
  fromBar: number;
  toBar: number;
  leftHand: boolean;
  rightHand: boolean;
}) => {
  playing = true;
  audioContext = metronome.getAudioContext();
  const tempo: number = metronome.getTempo();

  // calc starting time accorgin to midi clock
  const midiMinusContextTime = msToS(Webmidi.time) - audioContext.currentTime;
  challengeStartingTime = timeToStart;
  midiStartingTime = timeToStart + midiMinusContextTime;

  debug("building expected notes path ", fromBar, toBar);
  const node = buildExpectedNotesPath({
    exerciseNotes,
    fromBar,
    toBar,
    leftHand,
    rightHand,
  });

  setSecondsOffsetToNodes(
    node,
    tempo,
    exerciseNotes.timeNumerator,
    exerciseNotes.timeDenominator,
    fromBar
  );

  debug("First node starting time", node.secondsOffset);

  currentNode = node;

  scheduleNotePaint(node, timeToStart, audioContext);

  const midiInput = await getSelectedMidiDevice();
  midiInput.removeListener("noteon", "all");

  if (midiInput == null)
    throw new Error("Tried to start challenge mode but midi input is null");

  if (
    !midiInput.hasListener(
      "noteon",
      "all",
      getChallengeModeEventHandler(audioContext)
    )
  )
    midiInput.addListener(
      "noteon",
      "all",
      getChallengeModeEventHandler(audioContext)
    );
};

const printPath = (root: PlayableNode) => {
  let node = root;
  let i = 0;
  while (node != null) {
    debug(`${i++}:  ${node}}`, node);
    node = node.nextNode;
  }
};

/**
 *
 * @param node
 * @returns Seconds since the beginning of the challenge mode according to midi time;
 */
const getNodeMidiTime = (node: PlayableNode): number => {
  return node.secondsOffset + midiStartingTime;
};

/**
 * When called, schedules the expectation of the received node
 */
const scheduleNotePaint = (
  node: PlayableNode,
  startingTime: number,
  audioContext: AudioContext
) => {
  if (node == null) {
    return;
  }

  const nodeStartingTime = startingTime + node.secondsOffset;

  const msUntilNoteStartTime = sToMs(
    nodeStartingTime - audioContext.currentTime
  );

  setTimeout(() => {
    if (!playing) return;

    scheduleNotePaint(node.nextNode, startingTime, audioContext);

    if (node.isFulfilled) return;
    paintNotes(node.notesList, "blue");
    discardNodeWhenCorresponding(node);
  }, msUntilNoteStartTime - ERROR_MARGIN - 10); // 10ms margin for settimeout error margin and make it better for users
};

/**
 * Timer to discard the node when it's expired (when the current time is greater than the node time plus the error margin)
 * It makes sure that the node is discarded with the best possible timing that the javascript clock can provide
 * Still, the node could have been discarded before this method is called, like when it's fulfilled or then during
 * and incoming noteOn event, it's determined that the node is already expired (with more precition since it uses the webmidi clock)
 *
 * @param node
 */
const discardNodeWhenCorresponding = (node: PlayableNode) => {
  if (node != currentNode) return; //Alreadu expired

  const midiTimeNow = Webmidi.time; // ms
  const nodeWMtime = sToMs(getNodeMidiTime(node));

  if (midiTimeNow - nodeWMtime > ERROR_MARGIN) {
    discardAndGoToNextNode();
  } else {
    setTimeout(() => {
      discardNodeWhenCorresponding(node);
    }, midiTimeNow - (nodeWMtime + ERROR_MARGIN + 5));
  }
};

const goToNextNode = () => {
  if (currentNode == null) return;

  currentNode = currentNode.nextNode;
};

const discardAndGoToNextNode = () => {
  if (!currentNode.isFulfilled) paintNotes(currentNode.notesList, "red");
  goToNextNode();
};

const getChallengeModeEventHandler = (audioContext: AudioContext) => {
  return (e: InputEventNoteon) => {
    // Sanity: If playing has ended or current node is null, ignore
    if (!playing) return;
    if (currentNode == null) return;

    const msSinceNodeExpired =
      e.timestamp - sToMs(getNodeMidiTime(currentNode));
    const eventError = Math.abs(msSinceNodeExpired);

    if (currentNode.isFulfilled || msSinceNodeExpired > ERROR_MARGIN) {
      discardAndGoToNextNode();
    }

    let keyResult = null;

    if (eventError > ERROR_MARGIN) {
      // shouldn't play this note yet.
      playErrorSound();
    } else {
      keyResult = currentNode.handleNewKeydown(e);

      if (!keyResult) {
        playErrorSound();
      }

      if (keyResult && currentNode.isFulfilled) {
        discardAndGoToNextNode();
      }
    }

    if (
      getSelectedPlaybackOption() == PlaybackOption.ALWAYS ||
      (keyResult && getSelectedPlaybackOption() == PlaybackOption.OK_NOTES)
    ) {
      midiPlayer.playNote(e.note.name + e.note.octave);
    }
  };
};

const setSecondsOffsetToNodes = (
  root: PlayableNode,
  tempo: number,
  timeNumerator: number,
  timeDenominator: number,
  measuresOffset: number = 0
) => {
  debug("setting seconds to nodes with offset", measuresOffset);
  let target = root;

  while (target != null) {
    target.setSecondsOffset(
      tempo,
      timeNumerator,
      timeDenominator,
      measuresOffset
    );
    target = target.nextNode;
  }
};

const stopChallengeMode = () => {
  playing = false;
  resetPaintedNotes();
  currentNode = null;

  getSelectedMidiDevice().then((input) => {
    input.removeListener("noteon", "all");
  });
};

const playErrorSound = () => {
  setupErrorSoundOscillator();
  oscillator.start();
  oscillator.stop(audioContext.currentTime + 0.0014);
};

const setupErrorSoundOscillator = () => {
  const volume = audioContext.createGain();
  volume.connect(audioContext.destination);
  volume.gain.value = 0.4;

  oscillator = audioContext.createOscillator();
  oscillator.type = "square";
  oscillator.frequency.value = 200;

  oscillator.connect(volume);
};

const sToMs = (seconds: number) => {
  return seconds * 1000;
};

const msToS = (milliseconds: number) => {
  return milliseconds / 1000;
};

export { startChallengeMode, stopChallengeMode };
