import fdebug from "debug";
import MidiNote from "./MidiNote";
const debug = fdebug("app:hearUserInput:PlayableNode");

import Note from "../score/Note";

import { getMidiNumber } from "../keyService";
import { paintNote } from "../context";
import { scrollToNote } from "../score/scrolling";
import { NoteNameWithOctave } from "../../types/NoteNameWithOctave";
import { InputEventNoteon } from "webmidi";
import { NoteName } from "../../types/NoteName";

/**
 * @class
 */
export default class PlayableNode {
  secondsOffset: number;
  notesList: Note[];
  nextNode: PlayableNode;
  keys: { [key: number]: { fulfilled: boolean; key: NoteNameWithOctave } };
  keysCount: number;
  isFulfilled: boolean;
  keysFulfilled: number;
  resettingTimeout: NodeJS.Timeout;
  timedout: boolean;
  index: number;

  /**
   * A node representing a set of keys that are expected to be fulfilled
   */
  constructor(index: number) {
    this.notesList = [];
    this.nextNode = null;
    this.keys = {};
    this.keysCount = 0;
    this.isFulfilled = false;
    this.secondsOffset = null;
    // Fulfillment status attributes
    this.keysFulfilled = 0;
    // this.chordTimeout = null;
    // this.lastTimestamp = null;
    this.resettingTimeout = null;
    this.timedout = false;
    this.index = index;
  }

  setSecondsOffset(
    tempo: number,
    timeNumerator: number,
    timeDenominator: number,
    measuresOffset: number
  ) {
    if (this.notesList.length === 0) return;

    this.secondsOffset = this.notesList[0].noteStartOffset(
      tempo,
      timeNumerator,
      timeDenominator,
      measuresOffset
    );
  }

  /** */
  reset() {
    this.keysFulfilled = 0;
    this.isFulfilled = false;

    Object.keys(this.keys).forEach((key) => {
      this.keys[key].fulfilled = false;
    });
  }

  /**
   * Function to push a note to the notes List. Autmatically updating
   * @param {Note} note
   */
  pushNote(note: Note) {
    this.notesList.push(note);

    note.keysWithAppliedSignature.forEach((key: NoteNameWithOctave) => {
      const [keyNote, octave] = key.split("/");
      const midiNumber = getMidiNumber(keyNote as NoteName, parseInt(octave));

      if (!note.options.rest) {
        this.keysCount++;
        this.keys[midiNumber] = {
          fulfilled: false,
          key: key,
        };
      }
    });
  }

  /**
   * @param {InputEventNoteon} keyData
   * @return {boolean} true if the note was being expected
   */
  handleNewKeydown(keyData: InputEventNoteon): boolean {
    const { name, number, octave } = keyData.note;
    const midiNote = new MidiNote({
      name,
      number: number.toString(),
      octave: octave.toString(),
    });
    const midiNumber = keyData.note.number;
    let result = false;
    if (this.keys[midiNumber] != null && !this.keys[midiNumber].fulfilled) {
      this.keys[midiNumber].fulfilled = true;
      this.keysFulfilled++;
      result = true;
    }

    if (this.keysFulfilled === this.keysCount) {
      this.isFulfilled = true;
      this.notesList.forEach((note) => {
        paintNote(note, "green");
      });
    } else {
      this.resettingTimeout = setTimeout(() => {
        this.resetExpectation();
      }, 250);
    }
    return result;
  }

  /**
   * Start expecting for user input
   */
  startExpecting() {
    // Wait a little bit before scrolling to user can se the response first
    setTimeout(() => {
      scrollToNote(this.notesList[0]);
    }, 200);

    this.notesList.forEach((note) => {
      paintNote(note, "blue");
    });
  }

  /**
   * toString override
   * @return {String}
   */
  toString(): string {
    let str = "PlayableNode: ";
    Object.keys(this.keys).forEach((key) => {
      str += key += ",";
    });
    return str;
  }

  /**
   * Stops waiting for remaining notes and
   */
  resetExpectation() {
    // this.chordTimeout = null;
    this.keysFulfilled = 0;
    for (const key in this.keys) {
      if (this.keys[key] != null) {
        this.keys[key].fulfilled = false;
      }
    }
  }
}
