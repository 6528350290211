import Note from "./Note";
import Vex from "vexflow";
/** */
export default class Voice {
  /**
   *
   * @param {Vex.Flow.Voice} vfVoice
   * @param {Note[]} notes
   */
  constructor(vfVoice, notes) {
    this.notes = notes;
    this.vfVoice = vfVoice;
  }
}
