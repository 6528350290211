import ui from "./metronomeUI";

import fdebug from "debug";
const debug = fdebug("app:metronome");

// variables
let audioContext = AudioContext.prototype; // for types
let metronomeVolume;

// configurable variables
let tempo = 80.0;
let beatsPerMeasure = 4;
let timesPerBeat = 1; // the program fails if this is > 1 right now, fix is a
// TODO.
let beepLength = 1.0 / 10; // secs
let scheduleAhead = 1; // seconds in which the beeps are scheduled
let timeGap;

let stopAt = 99999999;

// eslint-disable-next-line
let startTime;
// eslint-disable-next-line
let nextMeasureStart;

let beepsQueue = [];

let osc;

// state variables
let lastScheduledTime = -1;
let currentBeat;
let scheduler = null;

/**
 * Initializer function
 * @param {Number} timesPerMeasure
 * @return {Object} this metronome instance.
 */
function init(timesPerMeasure, canvasRef) {
  beatsPerMeasure = timesPerMeasure;
  // @ts-ignore doesn't understand audiocontext
  audioContext = new (window.AudioContext || window.webkitAudioContext)();
  metronomeVolume = audioContext.createGain();
  setVolume(0.1);
  metronomeVolume.connect(audioContext.destination);
  ui.init(canvasRef)
  return this;
}
/**
 *
 * @param {Number} bpm
 */
function setBeatsPerMeasure(bpm) {
  beatsPerMeasure = bpm;
}

/** */
function initUI() {
  ui.init();
}

/**
 * Starts the metronome with the configuration as it is.
 * @return {Object} Time in seconds when the first and
 *                  scond metronomeBeat Starts.
 */
function start() {
  timeGap = 60.0 / this.tempo / timesPerBeat; // gap between beats in seconds
  startTime = audioContext.currentTime;
  currentBeat = -1;
  lastScheduledTime = null;
  let firstBeatTime = audioContext.currentTime + 0.1;
  let nextMeasureFirstBeatTime = firstBeatTime + timeGap * beatsPerMeasure;
  // this scheduler is running every scheduleAhead/2 seconds, and
  // each time it runs, it schedules every beep corresponding for the next
  // scheduleAhead seconds.
  scheduler = setInterval(() => {
    if (lastScheduledTime < audioContext.currentTime + scheduleAhead) {
      while (lastScheduledTime < audioContext.currentTime + scheduleAhead) {
        if (lastScheduledTime == null) {
          scheduleBeep(firstBeatTime);
          lastScheduledTime = firstBeatTime;
        }
        scheduleBeep(lastScheduledTime + timeGap);
        // debug("last sched: " + lastScheduledTime + timeGap);
        lastScheduledTime += timeGap;
      }
    }
  }, scheduleAhead / 2);
  return { firstBeatTime, nextMeasureFirstBeatTime };
}

/**
 * Beeps once
 * @param {Number} start time when the beep starts in seconds.
 */
function scheduleBeep(start) {
  let beep = {};
  let beatIndex =
    beepsQueue.length === 0
      ? 0
      : beepsQueue[beepsQueue.length - 1].beatIndex + 1;

  Object.assign(beep, { beatIndex: beatIndex });

  // debug(
  //   "scheduling beat " +
  //   beep.beatIndex +
  //   " in " +
  //   (start - audioContext.currentTime) +
  //   " seconds, indexInMeasure: " +
  //   (beep.beatIndex % beatsPerMeasure)
  // );

  let freq =
    beep.beatIndex % (beatsPerMeasure * timesPerBeat) === 0
      ? 880
      : beep.beatIndex % timesPerBeat === 0
        ? 440
        : 220;

  if (freq === 880) {
    nextMeasureStart = start;
  }

  let timeout = setTimeout(() => {
    // debug("starting beepStartHandler");
    beepStartHandler(beep);
  }, (start - audioContext.currentTime) * 1000);

  osc = audioContext.createOscillator();

  osc.connect(metronomeVolume);
  osc.frequency.value = freq;
  osc.start(start);
  osc.stop(start + beepLength);
  osc.onended = (event) => {
    if (event.target === beepsQueue[0].osc) {
      // checking sanity
      // debug(beepsQueue.shift().beatIndex);
    } else {
      // throw new Error(
      //   "The oscillator that stoped " + "wasnt the first in the queue"
      // );
    }
  };

  Object.assign(beep, { start: start, osc: osc, handlerTimeout: timeout });
  beepsQueue.push(beep);
}

/** */
function stop() {
  clearInterval(scheduler); // stops scheduling
  ui.reset();
  while (beepsQueue.length > 0) {
    let beep = beepsQueue.shift();
    let osc = beep.osc;
    osc.onended = null;
    osc.stop();
    clearTimeout(beep.handlerTimeout);
  }
}

/** tells the metronome to stop after the beatIndex beat
 * @param {Number} beatIndex
 */
function stopAtBeatIndex(beatIndex) {
  stopAt = beatIndex;
}

/**
 * volume getter.
 * @return {Number} value between 0 and 1 representing the current volume.
 */
function getVolume() {
  debug("returining volume " + metronomeVolume.gain.value);
  return metronomeVolume.gain.value * 2;
}

/**
 * volume setter.
 * @param {Number} value volume between 0 and 1.
 */
function setVolume(value) {
  metronomeVolume.gain.value = value / 2;
}

/**
 * @return {Number}
 */
function getTimesPerMeasure() {
  return beatsPerMeasure;
}

/**
 * tempo getter.
 * @return {Number} current tempo in bpm;
 */
function getTempo() {
  return this.tempo;
}

/**
 * tempo setter
 * @param {Number} tempo
 */
function setTempo(tempo) {
  this.tempo = tempo;
}

/*
{
  clearInterval(scheduler); // stops scheduling
  while (beepsQueue.length > 0) {
    let osc = beepsQueue.shift().osc;
    osc.onended = null;
    osc.stop();
  }
  while (uiBeepsQueue.length > 0) {
    clearTimeout(uiBeepsQueue.shift());
  }
} */

/**
 * will return -1 if no beat has started.
 * @return {Number} current metronome beat.
 */
function getCurrentBeat() {
  return currentBeat;
}

/**
 *
 * @param {Object} beep
 */
function beepStartHandler(beep) {
  currentBeat = beep.beatIndex;
  if (currentBeat >= stopAt) {
    stop();
  }
  // debug("dispatching tick event with beat index " + beep.beatIndex);
  window.dispatchEvent(
    new CustomEvent("metronomeTick", {
      detail: { currentBeat: beep.beatIndex },
    })
  );

  ui.tick(beep.beatIndex % beatsPerMeasure); // update ui
}

/**
 * @return {AudioContext} metronome's audioContext
 */
function getAudioContext() {
  return audioContext;
}

export default {
  init,
  initUI,
  start,
  stop,
  stopAtBeatIndex,
  getAudioContext,
  getCurrentBeat,
  tempo,
  beatsPerMeasure,
  getVolume,
  setVolume,
  getTempo,
  setTempo,
  setBeatsPerMeasure,
  getTimesPerMeasure,
};
