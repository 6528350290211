"use-strict";
import vex from "vexflow";
import fdebug from "debug";
const debug = fdebug("app:ScoreBuilder");

import ExerciseNotes from "./ExerciseNotes";
// import ExerciseModel from "../../models/exerciseInput/exercise";

import Measure from "./Measure"; // Array of:
import Stave from "./Stave"; // Array of:
import Note from "./Note";
import Voice from "./Voice";


/**
 * this class is in charge of drawing the score in the div, using the vexFlow
 * API.
 * TODO: Migrate to TS.
 */
export default class ScoreBuilder {
  /**
   *
   * @param {*} divId
   */
  constructor(divId) {
    this.VF = vex.Flow;
    this.div = document.getElementById(divId);
    this.availableWidth = this.div.offsetWidth;
    this.renderer = new this.VF.Renderer(
      this.div,
      this.VF.Renderer.Backends.SVG
    );

    // @ts-ignore
    this.renderer.resize("100%", 100); // width, height
    this.context = this.renderer.getContext();
    debug("created context");

    // left upper corner point of first measure;
    this.stavePositionX = 30;
    this.stavePositionY = 40;
    this.currentLineFirstMeasure = 0;

    this.currentStave = null;
    this.system = null;

    this._timeNum = 4;
    this._timeDen = 4;

    this.nMeasures = 0;
    this.exerciseNotes = new ExerciseNotes();
    this.tuplets = [];
  }

  /**
   * TODO: Migrate to TS.
   * @param {import("../../types/exerciseInput/exercise").InputExercise} exercise
   * @return {ExerciseNotes}
   */
  drawScore(exercise) {
    this.time = exercise.config.time;
    this.exerciseNotes.timeNumerator = this._timeNum;
    this.exerciseNotes.timeDenominator = this._timeDen;
    exercise.measures.forEach((inputMeasure, measureIndex) => {
      const measure = new Measure([], measureIndex);

      inputMeasure.staves.forEach((inputStave, staveIndex) => {
        const stave = new Stave(measureIndex, []);

        inputStave.voices.forEach((inputVoice, voiceIndex) => {
          /** @type {Note[]} */
          const voiceNotes = inputVoice.notes.map((inputNote) => {
            const noteOptions = inputNote.options || {};
            Object.assign(noteOptions, exercise.config, {
              clef: exercise.config.clefs[staveIndex],
            });
            return new Note(
              inputNote.keys,
              inputNote.duration,
              inputNote.figure,
              noteOptions,
              measure
            );
          });

          // Before the voice is created, create and assign tuples
          this.makeTuples(voiceNotes);
          const voice = new Voice(null, voiceNotes);

          stave.voices.push(voice);
        });

        measure.staves.push(stave);
      });

      this._createMeasureStaves(measure, measureIndex, exercise.config);

      this.exerciseNotes.measures.push(measure);
      this.nMeasures++;
    });

    this.exerciseNotes.measures.forEach((measure) => {
      this.calculateNotesTimes(measure);
      this._drawMeasureV2(measure);
    });

    this.drawTuples();
    this.drawTiesV2();

    debug("Exercise Notes V2", this.exerciseNotes);

    return this.exerciseNotes;
  }

  makeTuples(voiceNotes) {
    let currentTupletNotes = [];
    voiceNotes.forEach((note) => {
      /** @type {import("../../types/exerciseInput/exerciseNoteOption").TimeModification} */
      const noteTimeMod = note.options.timeModification;
      if (noteTimeMod == null) return;

      const { normalNotes, actualNotes } = noteTimeMod;

      currentTupletNotes.push(note.vfNote);

      if (currentTupletNotes.length === actualNotes) {
        const tuplet = new vex.Flow.Tuplet(
          currentTupletNotes,
          { notes_occupied: (normalNotes || 2) }
        );
        this.tuplets.push(tuplet);
        currentTupletNotes = [];
      }
    });
  }

  drawTuples() {
    this.tuplets.forEach(tuplet => {
      tuplet.setContext(this.context).draw();
    })
  }

  /**
   * Checks measure duration
   * @param {Measure} measure
   */
  calculateNotesTimes(measure) {
    measure.staves.forEach((stave, staveIndex) => {
      stave.voices.forEach((voice, voiceIndex) => {
        let voiceDuration = 0.0;
        voice.notes.forEach((note, noteIndex) => {
          note.measureIndex = measure.index;
          note.measureStartingPoint = voiceDuration;
          voiceDuration += note.duration;
        });
        this.checkMeasureDuration(voiceDuration);
      });
    });
  }

  /**
   *
   * @param {Measure} measure
   * @return {Voice[]}
   */
  _getAllMeasureVoices(measure) {
    const voices = [];

    measure.staves.forEach((stave) => {
      voices.push(...stave.voices);
    });

    return voices;
  }

  /**
   * @param {Measure} exerciseMeasure
   * @param {Number} measureIndex
   * @param {import("../../types/exerciseInput/exerciseConfig").InputExerciseConfig} exerciseConfig
   */
  _createMeasureStaves(exerciseMeasure, measureIndex, exerciseConfig) {
    const stavesArr = [];
    const nTickables = Math.max(
      ...this._getAllMeasureVoices(exerciseMeasure).map(
        (voice) => voice.notes.length
      )
    );
    exerciseMeasure.staves.forEach((exStave, staveIndex) => {
      const voices = exStave.voices;

      const extraSpace = this.nMeasures == 0 ? 100 : 0;

      const vfStave = this._makeStave(
        // TODO: replace this with a function that calculates the space needed for a stave considering available space
        // and note durations.
        100 + this.tickableHorizontalSpace() * nTickables + extraSpace,
        staveIndex,
        staveIndex === exerciseMeasure.staves.length - 1,
        measureIndex,
        exerciseConfig
      );

      exStave.vfStave = vfStave;

      voices.forEach((voice) => {
        const vfVoice = new this.VF.Voice({
          num_beats: this._timeNum,
          beat_value: this._timeDen,
        });

        vfVoice.addTickables(voice.notes.map((note) => note.vfNote));
        voice.vfVoice = vfVoice;
      });
    });
  }

  /**
   *
   * @param {Measure} measure
   */
  _drawMeasureV2(measure) {
    const allVfVoices = [];

    measure.staves.forEach((stave) => {
      allVfVoices.push(...stave.voices.map((voice) => voice.vfVoice));
    });

    // creating connectors
    let connectorLeft = new this.VF.StaveConnector(
      measure.staves[0].vfStave,
      measure.staves[measure.staves.length - 1].vfStave
    );
    let connectorRight = new this.VF.StaveConnector(
      measure.staves[0].vfStave,
      measure.staves[measure.staves.length - 1].vfStave
    );

    connectorLeft.setType(this.VF.StaveConnector.type.SINGLE_LEFT);
    connectorLeft.setContext(this.context);
    connectorRight.setType(this.VF.StaveConnector.type.SINGLE_RIGHT);
    connectorRight.setContext(this.context);

    // formatting
    let formatter = new this.VF.Formatter();
    allVfVoices.forEach((voice) => {
      formatter.joinVoices([voice]);
    });
    formatter.formatToStave(allVfVoices, measure.staves[0].vfStave);

    // drawing
    measure.staves.forEach((stave) => {
      stave.vfStave.draw();

      stave.voices.forEach((voice) => {
        const beams = this.VF.Beam.generateBeams(
          voice.notes.map((note) => {
            return note.vfNote;
          })
        );
        voice.vfVoice.draw(this.context, stave.vfStave);

        beams.forEach((beam) => {
          beam.setContext(this.context).draw();
        });
      });
    });
    connectorLeft.setContext(this.context).draw();
    connectorRight.setContext(this.context).draw();
  }

  /** */
  drawTiesV2() {
    let maxStaves = 0;
    let maxVoices = 0;

    this.exerciseNotes.measures.forEach((measure) => {
      maxStaves = Math.max(maxStaves, measure.staves.length);
      maxVoices = Math.max(maxVoices, measure.staves[0].voices.length);
    });

    for (let si = 0; si < maxStaves; si++) {
      for (let vi = 0; vi < maxVoices; vi++) {
        const voice = this.getFullVoice(si, vi);

        // drawing ties
        let ties = [];
        let tieStart = null;
        debug("drawing ties, measuresa: " + voice.length);
        debug(voice);

        for (let i = 0; i < voice.length; i++) {
          // ith measure
          for (let j = 0; j < voice[i].notes.length; j++) {
            // jth note of meas.
            if (
              voice[i].notes[j].options &&
              voice[i].notes[j].options.tieStart
            ) {
              tieStart = voice[i].notes[j];
            } else if (
              voice[i].notes[j].options &&
              voice[i].notes[j].options.tieEnd
            ) {
              if (tieStart == null) {
                throw new Error("trying to end a tie that never started");
              }

              debug("from measure: " + tieStart.measureIndex);
              debug("to measure: " + voice[i].notes[j].measureIndex);
              if (tieStart.measureIndex != voice[i].notes[j].measureIndex) {
                let tie1 = new this.VF.StaveTie({
                  first_note: tieStart.vfNote,
                  last_note: null,
                  first_indices: tieStart.options.tieStart,
                  last_indices: voice[i].notes[j].options.tieEnd,
                });
                tie1.setContext(this.context);
                debug(`drawing tie to ${i}:${j}`);
                tie1.draw();
                ties.push(tie1);

                let tie2 = new this.VF.StaveTie({
                  first_note: null,
                  last_note: voice[i].notes[j].vfNote,
                  first_indices: tieStart.options.tieStart,
                  last_indices: voice[i].notes[j].options.tieEnd,
                });
                tie2.setContext(this.context);
                debug(`drawing tie to ${i}:${j}`);
                tie2.draw();
                ties.push(tie1);

                tieStart = null;
              } else {
                let tie = new this.VF.StaveTie({
                  first_note: tieStart.vfNote,
                  last_note: voice[i].notes[j].vfNote,
                  first_indices: tieStart.options.tieStart,
                  last_indices: voice[i].notes[j].options.tieEnd,
                });
                tie.setContext(this.context);
                debug(`drawing tie to ${i}:${j}`);
                tie.draw();
                ties.push(tie);
                tieStart = null;
              }
            }
          }
        }
        ties.forEach((tie) => tie.setContext(this.context).draw());
      }
    }
  }

  /**
   *
   * @param {number} staveIndex
   * @param {number} voiceIndex
   * @return {Voice[]}
   */
  getFullVoice(staveIndex, voiceIndex) {
    const voices = [];

    this.exerciseNotes.measures.forEach((measure) => {
      if (
        measure.staves.length > staveIndex &&
        measure.staves[staveIndex].voices.length > voiceIndex
      ) {
        voices.push(measure.staves[staveIndex].voices[voiceIndex]);
      }
    });

    return voices;
  }

  /**
   * 
   * @returns number
   */
  tickableHorizontalSpace() {
    if (this.availableWidth < 500) {
      return 18;
    } else if (this.availableWidth < 980) {
      return 20;
    } else {
      return 25;
    }
  }

  // eslint-disable-next-line valid-jsdoc
  /**
   * creates a new stave, next to the current one
   * @param {Number} width        width of the stave
   * @param {Number} staveIndex   Index of the current voice, this is to calc
   *                              the Y position of the stave.
   * @param {Boolean} lastStave   wheter it's the last voice of the measure or
   *                              not.
   * @param {Number} measureIndex
   * @param {import("../../types/exerciseInput/exerciseConfig").InputExerciseConfig} exerciseConfig
   * @return {vex.Flow.Stave}
   */
  _makeStave(width, staveIndex, lastStave = false, measureIndex, exerciseConfig) {
    let drawMeasureNumber = measureIndex === 0 && staveIndex === 0;
    const availableWidthMax = (this.availableWidth - 20) || 980; // margin
    const isFirstMeasureOfLine = this.currentLineFirstMeasure == null || this.currentLineFirstMeasure == measureIndex;

    if (isFirstMeasureOfLine) {
      this.currentLineFirstMeasure = measureIndex;
    }


    this.availableWidth = this.availableWidth || 980;
    if (this.stavePositionX + width > availableWidthMax && !isFirstMeasureOfLine) {
      this.stavePositionX = 30;
      this.stavePositionY += 220 + 100 * staveIndex;
      drawMeasureNumber = true;
      this.currentLineFirstMeasure = measureIndex;
    }

    let stave = new this.VF.Stave(
      this.stavePositionX, this.stavePositionY + 100 * staveIndex, width
    );
    if (drawMeasureNumber) {
      this._addMeasureNumber(stave, measureIndex);
    }

    if (this.stavePositionX == 30) {
      stave.addClef(exerciseConfig.clefs[staveIndex]);

      if (measureIndex == 0)
        stave.addTimeSignature(exerciseConfig.time);

      if (exerciseConfig.keySignature) {
        new this.VF.KeySignature(exerciseConfig.keySignature).addToStave(
          stave
        );
      }
    }
    stave.setContext(this.context);

    // @ts-ignore
    this.renderer.resize("100%", this.stavePositionY + 100 * staveIndex + 200);
    if (lastStave) {
      // then we just draw the last voice for the measure, so
      // the next voice will be for another measure.
      this.stavePositionX += width;
    }
    return stave;
  }

  /**
   *
   * @param {vex.Flow.Stave} stave
   * @param {Number} index index of the measure starting from 0 (will be drawn +1)
   */
  _addMeasureNumber(stave, index) {
    const measureNumberPositionOptions = {
      justification: 1,
      shift_x: 0,
      shift_y: 15,
    };

    stave.setText(
      (index + 1).toString(),
      this.VF.StaveModifier.Position.ABOVE,
      measureNumberPositionOptions
    );
  }

  /**
   * checks measure duration.
   * @param {Number} measureDuration
   */
  checkMeasureDuration(measureDuration) {
    if (measureDuration == this._timeNum / this._timeDen) {
      debug("measure is Ok, adding it");
    } else {
      throw new Error("measure makes no sense, duration: " + measureDuration);
    }
  }

  /**
   * time getter
   */
  get time() {
    return this._timeNum + "/" + this._timeDen;
  }

  /**
   * time setter
   * @param {String} time time in 4/4 format.
   */
  set time(time) {
    [this._timeNum, this._timeDen] = time
      .split("/")
      .map((time) => parseInt(time));
  }
}
