import { Box, Button, IconButton, duration } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import React, { Dispatch, SetStateAction, useState } from "react";
import { getComponentForDuration } from "../../figures";
import { Rythm, RythmElement } from "../../../types/Rythm";
import { NoteDuration } from "../../../types/noteDuration";
import { createRythmElement, createRythmElementWithDefaults, getRythmElementDuration } from "../../lib/rythm";

import RythmPreview from "./RythmPreview"

const useStyles = makeStyles({
  selected: {
    backgroundColor: "lightblue",
    '&:hover': {
      backgroundColor: "#3faabf",
   },
  },

});


const RythmSelector = ({ rythms, selectedRythmIds, setSelectedRythmIds }: { rythms: Rythm[], selectedRythmIds: string[], setSelectedRythmIds: Dispatch<SetStateAction<string[]>> }) => {
  const classes = useStyles()

  React.useEffect(() => {
    console.log(selectedRythmIds)
  }, [selectedRythmIds])

  const getToggleRythmHandler = (rythmId) => {
    const alreadyPresent = selectedRythmIds.includes(rythmId) 
    return () => {
      if (alreadyPresent) {
        setSelectedRythmIds(selectedRythmIds => {
          if (selectedRythmIds.length == 1) return [rythmId]

          return [...selectedRythmIds.filter(i => i != rythmId)]
        })
      } else {
        setSelectedRythmIds(selectedRythmIds => {
          return [...selectedRythmIds, rythmId]
        })
      }
    }
  }

  return <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', mt: 2, width: '100%' }}>
    {rythms.map((rythm, index) => {
      return (
        <Button onClick={getToggleRythmHandler(rythm.id)} className={selectedRythmIds.includes(rythm.id) ? classes.selected : ""} sx={{ border: '1px solid', borderRadius: '5px', mt: 1, mr: 1, pb: 0 }}>
          <RythmPreview rythm={rythm} />
        </Button>
      )
    })}
  </Box>
}





export default RythmSelector
