import PropTypes from "prop-types";
import { Grid, TextField } from "@mui/material";
import React, { Fragment } from "react";
import InAndOut from "./InAndOut";

export const BarSelector = ({
  playFromBar,
  playToBar,
  setPlayFromBar,
  setPlayToBar,
  showFullControls,
}) => {
  return (
    <InAndOut showWhen={showFullControls}>
      <div>
        <Grid container spacing={0} my={2}>
          <Grid item md={6}>
            <TextField
              id="listenFromBar"
              label="From Bar"
              value={getBarNumberToDisplay(playFromBar)}
              type="number"
              variant="outlined"
              onChange={(e) => {
                setPlayFromBar(getBarNumberToStore(parseInt(e.target.value)));
              }}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              id="listenToBar"
              label="ToBar"
              value={getBarNumberToDisplay(playToBar)}
              type="number"
              variant="outlined"
              onChange={(e) => {
                setPlayToBar(getBarNumberToStore(parseInt(e.target.value)));
              }}
            />
          </Grid>
        </Grid>
      </div>
    </InAndOut>
  );
};

BarSelector.propTypes = {
  playFromBar: PropTypes.any,
  playToBar: PropTypes.any,
  setPlayFromBar: PropTypes.func,
  setPlayToBar: PropTypes.func,
  showFullControls: PropTypes.bool,
};

/**
 * @param {Number} displayedBar the bar displayed (starting from 1)
 * @return {Number} the bar number to save and use (starting from 0)
 */
const getBarNumberToStore = (displayedBar) => {
  return displayedBar != null ? displayedBar - 1 : null;
};

/**
 *
 * @param {Number} storedBar
 * @return {Number} displayedBar
 */
const getBarNumberToDisplay = (storedBar) => {
  return storedBar != null ? storedBar + 1 : null;
};
