import fdebug from "debug";
import { ChordProgressionChord } from "../../types/ChordProgression";
import { InputExercise } from "../../types/exerciseInput/exercise";
import { InputExerciseMeasure } from "../../types/exerciseInput/exerciseMeasure";
import { InputExerciseNote } from "../../types/exerciseInput/exerciseNote";
import { MajorKey } from "../../types/MajorKey";
import { NoteDuration } from "../../types/noteDuration";
import { NoteNameWithOctave } from "../../types/NoteNameWithOctave";
import { NotesRange } from "../../types/NotesRange";
import { RandomExerciseConfig } from "../../types/RandomExerciseConfig";
import { UNALTERED_NOTES } from "../../types/UnalteredNotes";
import { UnalteredNoteWithOctave } from "../../types/UnalteredNoteWithOctave";
import { removeAlterations } from "../keyService/service";
import { addOctavesToNotes, generateChordNotes } from "./chordGenerator";
import progressions from "./chordProgressions";
import { TENSIONS } from "./Tensions";
import { Rythm } from "../../types/Rythm";
import { getRythmDuration, getRythmElementDuration } from "../../components/lib/rythm";
import { getRythmById } from "../../data/rythms";

const debug = fdebug("app:sightReadingCreator");

let notes = ["C", "D", "E", "F", "G", "A", "B"];

export const generateRandomExercise = (
  RandomExerciseConfig: RandomExerciseConfig
): InputExercise => {
  return generateRandomChordsAndMelody(RandomExerciseConfig);
};

// eslint-disable-next-line valid-jsdoc
/**
 *
 * @param {NotesRange}
 * @return {}
 */
export const makeAvailableNotesArray = ({
  noteFrom,
  noteTo,
}: NotesRange): UnalteredNoteWithOctave[] => {
  const [keyFrom, octaveFromStr] = removeAlterations(noteFrom).split("/");
  const [keyTo, octaveToStr] = removeAlterations(noteTo).split("/");

  const octaveFrom = parseInt(octaveFromStr);
  const octaveTo = parseInt(octaveToStr);
  const keyFromIndex = notes.indexOf(keyFrom);
  const keyToIndex = notes.indexOf(keyTo);

  const result = [];

  if (octaveTo < octaveFrom) {
    throw new Error(
      "Ending octave must be greater or equal than starting octave"
    );
  }

  if (octaveFrom === octaveTo && keyToIndex < keyFromIndex) {
    throw new Error("Notes range must be non-empty");
  }

  for (let octave = octaveFrom; octave <= octaveTo; octave++) {
    const indexFrom = octave == octaveFrom ? keyFromIndex : 0;
    const indexTo = octave == octaveTo ? keyToIndex : notes.length - 1;

    for (let i = indexFrom; i <= indexTo; i++) {
      result.push(`${notes[i]}/${octave}`);
    }
  }

  debug("Available notes array: ", result);

  return result;
};


/**
 * @return {InputExercise}
 */
export const generateRandomChordsAndMelody = (
  params: RandomExerciseConfig
): InputExercise => {
  const {
    key,
    numberOfBars,
    noteFrom,
    noteTo,
    tension,
    progressionIndex,
    rythmIds
  } = params;

  const exercise: InputExercise = getBaseExercise(key);
  const melodyNoteFrom: NoteNameWithOctave = noteFrom;
  const melodyNoteTo: NoteNameWithOctave = noteTo;
  const chordProgression = progressions[progressionIndex];
  const barsNumber = numberOfBars + (numberOfBars % chordProgression.length);

  let chordIndex = 0;
  for (let i = 0; i < barsNumber; i++) {
    const leftHandNotes: InputExerciseNote[] = [];

    const progressionChord = chordProgression.chords[chordIndex];
    chordIndex = (chordIndex + 1) % chordProgression.chords.length;

    const chordNotes = addOctavesToNotes(
      generateChordNotes(key, progressionChord.degree, TENSIONS[tension]),
      "E/3"
    );

    leftHandNotes.push({ keys: chordNotes, duration: 1 });

    let rightHandNotes: InputExerciseNote[] = [];
    
    const rythms = rythmIds.map(id => (getRythmById(id)))
    const maxRythmDuration = rythms.reduce((max, val) => (max = Math.max(max, getRythmDuration(val))), 0)

    // Exercises come out better when generated in two sequences of lenght 2, but that will mean that there'll
    // never be rounds, so if rounds are an option, only then will use only one sequence.
    if (maxRythmDuration <= 0.5) {
      rightHandNotes = [
        ...generateNotesSequence(
          progressionChord,
          chordNotes,
          { noteFrom: melodyNoteFrom, noteTo: melodyNoteTo },
          1,
          key,
          rythms
        ),
      ];
    }
    else {
      rightHandNotes = [
        ...generateNotesSequence(
          progressionChord,
          chordNotes,
          { noteFrom: melodyNoteFrom, noteTo: melodyNoteTo },
          0.5,
          key,
          rythms
        ),
        ...generateNotesSequence(
          progressionChord,
          chordNotes,
          { noteFrom: melodyNoteFrom, noteTo: melodyNoteTo },
          0.5,
          key,
          rythms
        ),
      ];
    }

    const measure: InputExerciseMeasure = {
      staves: [
        { voices: [{ notes: rightHandNotes }] },
        { voices: [{ notes: leftHandNotes }] },
      ],
    };
    exercise.measures.push(measure);
    debug("added notes to random notes and melody ");
    debug(exercise);
  }
  debug("generated Exercise", exercise);
  return exercise;
};

/**
 * Generates a sequence of length length where length=1 means a whole 4/4 bar
 * @param chord Chord over the notes will be played, this means that the first note will belong to the chord
 * @param key
 * @param length
 */
const generateNotesSequence = (
  chord: ChordProgressionChord,
  chordNotesWithOctaves: UnalteredNoteWithOctave[],
  range: NotesRange,
  length: number,
  key: MajorKey,
  rythms: Rythm[]
): InputExerciseNote[] => {
  let measureDuration = 0;
  let notes = [];

  while (measureDuration < length) {
    const remainingDuration = length - measureDuration
    const candidateRythms = rythms.filter(rythm => getRythmDuration(rythm) <= remainingDuration)

    if (candidateRythms.length == 0) {
      debug(`No figures available to fill remainig sequence length (${remainingDuration}), filling with silences`)
      notes.push(...getRestsForDuration(remainingDuration))
      break
    }

    const nextRythm = candidateRythms[Math.floor(Math.random() * candidateRythms.length)]
    notes.push(...generateNotesForRythm(chord, chordNotesWithOctaves, range, key, nextRythm, measureDuration))
    measureDuration += getRythmDuration(nextRythm)
    debug("measureDuration: " + measureDuration);
  }

  return notes;
};



const generateNotesForRythm = (
  chord: ChordProgressionChord,
  chordNotesWithOctaves: UnalteredNoteWithOctave[],
  range: NotesRange,
  key: MajorKey,
  rythm: Rythm,
  startingPoint: number,
): InputExerciseNote[] => {
  const availableNotesArray = makeAvailableNotesArray({
    noteFrom: range.noteFrom,
    noteTo: range.noteTo,
  });

  const chordNotes = generateChordNotes(
    key,
    chord.degree,
    chord.tensions || TENSIONS.TRIAD
  );

  const chordConsonantsNotes = availableNotesArray.filter((note) => {
    return (
      // return true if the available note is part of the chord notes but it's not the same as a chord note with an octave.
      chordNotes.indexOf(note.charAt(0).toString() as UNALTERED_NOTES) >= 0 && // Present in the chord when looking without octaves and
      chordNotesWithOctaves.indexOf(note) < 0 // Not present in the actual chord considering octaves (otherwise it would )
    );
  });

  const notes: InputExerciseNote[] = [];
  rythm.elements.forEach(rythmElement => {
    let nextSequenceNote =
      startingPoint == 0 || startingPoint == 0.5 && chordConsonantsNotes.length > 0
        ? chordConsonantsNotes[Math.floor(Math.random() * chordConsonantsNotes.length)]
        : availableNotesArray[
        Math.floor(Math.random() * availableNotesArray.length)
        ];

    let rest = false
    if (nextSequenceNote == null) {
      nextSequenceNote = "B/4"
      rest = true
    }
    

    notes.push({
      keys: [`${nextSequenceNote}`],
      duration: rythmElement.duration,
      options: {
        dotted: rythmElement.dotted,
        rest: rest
      }
    });
  })

  return notes;
};

/**
 * @param targetDuration Duration from 0 to 1 where 1 is the whole bar
 */
const getRestsForDuration = (targetDuration: number): InputExerciseNote[] => {
  const notes: InputExerciseNote[] = []

  let dotted = false;
  let remainingDuration = targetDuration;

  let candidateDuration: NoteDuration = 1
  const barDuration = (duration: NoteDuration) => (1 / duration as number)

  while (remainingDuration > 0) {
    let selectedDuration = null;
    let dotted = false
    while (barDuration(candidateDuration) > remainingDuration) {
      candidateDuration *= 2
    }

    if (barDuration(candidateDuration) * 1.5 <= targetDuration) {
      dotted = true
    }


    notes.push({
      keys: ["B/4"],
      duration: candidateDuration,
      options: {
        dotted: dotted,
        rest: true
      }
    })


    const addedDuration = dotted ? barDuration(candidateDuration) * 1.5 : barDuration(candidateDuration)
    remainingDuration -= addedDuration
  }

  return notes
}


const getBaseExercise = (key: MajorKey): InputExercise => {
  return {
    name: "Random exercise",
    id: "basic-random",
    config: {
      time: "4/4",
      clefs: ["treble", "bass"],
      keySignature: key,
    },
    measures: [],
  };
};

//Partition function
// const splitArray = (array: [], condition: ) => {
//   const pass = [], fail = [];
//   array.forEach((e, idx, arr) => (condition(e) ? pass : fail).push(e));
//   return [pass, fail];
// }