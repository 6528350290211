let audioContext = null;

const initAudioContext = () => {
  // @ts-ignore
  audioContext = new (window.AudioContext || window.webkitAudioContext)();
}

const getAudioContext = () => {
  if (audioContext == null)
    throw new Error("Cannot use Audio Context before initialization");

  return audioContext;
};

export { initAudioContext, getAudioContext };