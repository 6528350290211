import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import {
  IconButton,
  MenuItem,
  Select,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import MusicNote from "@mui/icons-material/MusicNote";
import Straighten from "@mui/icons-material/Straighten";
import { PlaybackOption } from "../../types/PlaybackOption";
import Stop from "@mui/icons-material/Stop";
import InAndOut from "./InAndOut";
import {
  getSelectedDeviceIndex,
  setSelectedDeviceIndex,
} from "../../services/IO/midiService";

export const MidiListener = ({
  playing,
  listenLeftHand,
  setListenLeftHand,
  listenRightHand,
  setListenRightHand,
  listenUserInput,
  midiInputs,
  selectedDeviceIndex,
  playbackOption,
  setPlaybackOption,
  startChallenge,
  stopChallenge,
  showFullControls,
  optionsDisabled,
}) => {
  const isDesktop = window.innerWidth > 850;
  const [selectedDevice, setSelectedDevice] = useState(
    getSelectedDeviceIndex()
  );

  if (midiInputs == null || midiInputs.length === 0) {
    return (
      <div>
        <span>
          <Typography variant="caption">
            No Midi keyboard found, connect a midi keyboard to your device to
            practice.
          </Typography>
        </span>
      </div>
    );
  }
  return (
    <>
      <InAndOut showWhen={showFullControls}>
        <div className="controls-config-section">
          <FormControl className="config-selector">
            <InputLabel id="playback-select-label">playback</InputLabel>
            <Select
              labelId="playback-select-label"
              label="playback"
              id="demo-simple-select"
              value={playbackOption}
              disabled={
                midiInputs == null || midiInputs.length === 0 || optionsDisabled
              }
              onChange={(e) => {
                setPlaybackOption(e.target.value);
              }}
            >
              <MenuItem value={PlaybackOption.NEVER}>Never</MenuItem>
              <MenuItem value={PlaybackOption.OK_NOTES}>Correct notes</MenuItem>
              <MenuItem value={PlaybackOption.ALWAYS}>Always</MenuItem>
            </Select>
          </FormControl>

          <FormControl className="config-selector">
            {selectedDeviceIndex != null ? (
              <>
                <InputLabel id="devices-select-label">device</InputLabel>
                <Select
                  autoWidth
                  value={selectedDevice}
                  label="device"
                  labelId="devices-select-label"
                  disabled={optionsDisabled}
                  variant="outlined"
                  onChange={(event) => {
                    console.log("Setting device index", event.target.value);
                    setSelectedDeviceIndex(Number(event.target.value));
                    setSelectedDevice(Number(event.target.value));
                  }}
                >
                  {midiInputs.map((device, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={index}

                        // onSelect={}
                      >
                        {device.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            ) : (
              <span>No Midi devices</span>
            )}
          </FormControl>
        </div>
      </InAndOut>
      <div className="controls-main-section">
        <IconButton
          aria-label="Wait for user input"
          title="Wait for user input"
          onClick={listenUserInput}
          disabled={
            midiInputs == null || midiInputs.length === 0 || optionsDisabled
          }
          size="large"
          className="text-icon-button max-50"
        >
          <Straighten fontSize="large" />
          <Typography variant="button" sx={{ fontSize: "0.65rem" }}>
            Notes only
          </Typography>
        </IconButton>

        {!playing && (
          <IconButton
            aria-label="Challenge"
            title="Practice notes and rythm"
            onClick={startChallenge}
            disabled={
              midiInputs == null || midiInputs.length === 0 || optionsDisabled
            }
            size="large"
            className="text-icon-button max-50"
          >
            <MusicNote fontSize="large" />
            <Typography variant="button" sx={{ fontSize: "0.65rem" }}>
              Notes &Rythm
            </Typography>
          </IconButton>
        )}

        {playing && (
          <IconButton
            aria-label="Challenge"
            title="Practice notes and rythm"
            disabled={!playing}
            onClick={stopChallenge}
            size="large"
            className="text-icon-button max-50"
          >
            <Stop fontSize="large" />
            <Typography variant="button" sx={{ fontSize: "0.65rem" }}>
              Stop
            </Typography>
          </IconButton>
        )}
      </div>
    </>
  );
};

MidiListener.propTypes = {
  selectedDeviceIndex: PropTypes.number,
  listenLeftHand: PropTypes.any,
  listenRightHand: PropTypes.any,
  listenUserInput: PropTypes.any,
  midiInputs: PropTypes.arrayOf(PropTypes.object),
  playing: PropTypes.any,
  setListenLeftHand: PropTypes.func,
  setListenRightHand: PropTypes.func,
};
