import { SvgIcon } from "@mui/material";
import * as React from "react";
import { Dot } from "./dot";

const White = ({ className, dotted = false }: { className: string, dotted: boolean }) => {
  return (
    <>
      <SvgIcon className={className} viewBox="0 0 20 40">
        <g className="vf-note">
          <g className="vf-stem">
            <path
              stroke-width="1.5"
              fill="none"
              stroke="black"
              stroke-dasharray="none"
              d="M 15 31 L 15 4"
            ></path>
          </g>
          <g className="vf-notehead">
            <path
              stroke-width="0.3"
              stroke="none"
              fill="black"
              stroke-dasharray="none"
              d="M 4.5365 32 M 11.8934 26.7771 C 12.0058 26.7771 12.1742 26.7771 12.2023 26.7771 C 12.2023 26.7771 12.2304 26.7771 12.2304 26.7771 C 12.2304 26.7771 12.3989 26.7771 12.5393 26.7771 C 15.0665 26.9175 16.5547 28.5742 16.5547 30.6522 C 16.5547 31.663 16.2178 32.8143 15.3754 33.9094 C 13.7748 36.0716 11.1634 37.251 8.8889 37.251 C 7.1198 37.251 5.6035 36.549 4.8734 35.1169 C 4.6488 34.5553 4.5365 34.0218 4.5365 33.4321 C 4.5365 30.3714 7.9622 26.9737 11.8934 26.7771 M 14.8138 28.3215 C 14.7014 28.2654 14.561 28.2373 14.4206 28.2373 C 13.5502 28.2373 12.09 28.7708 10.686 29.7255 C 8.0746 31.3542 5.9405 33.741 5.8562 35.1169 C 5.8562 35.1169 5.8562 35.145 5.8562 35.145 C 5.8562 35.6223 6.1651 35.8189 6.6986 35.8189 C 7.8499 35.8189 9.984 34.8361 11.9496 33.2636 C 13.9714 31.663 15.263 29.8659 15.263 28.9393 C 15.263 28.6304 15.1226 28.3777 14.8138 28.3215"
            ></path>
          </g>
        </g>
        {dotted && <Dot />}
      </SvgIcon>
    </>
  );
};

export default White;
