/**
 * @enum {String} Color
 */
export const COLORS = {
  DEFAULT: "black",
  OK: "green",
  NOK: "red",
  PLAYED: "gray",
};

export const AVAILABLE_TIMES = ["3/4", "4/4", "6/8"];
