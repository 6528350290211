import React from 'react'

export const Dot = () => (
    <>
        <g className="vf-modifiers">
            <path
                stroke-width="0.3"
                fill="black"
                stroke="none"
                stroke-dasharray="none"
                d="M22.01824 35 A2 2 0 0 0 18.01824 35M18.01824 35 A2 2 0 0 0 22.01824 35">
            </path>
        </g>
    </>
)
