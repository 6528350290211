import fdebug from "debug";
import { InputEventNoteon } from "webmidi";
import { PlaybackOption } from "../../types/PlaybackOption";
import { resetPaintedNotes } from "../context";
import ExerciseNotes from "../score/ExerciseNotes";
import { getSelectedMidiDevice } from "./midiService";
import PlayableNode from "./PlayableNode";
import midiPlayer from "./player";

const debug = fdebug("app:IO:MidiService");

let rootNode: PlayableNode = null;
let currentNode: PlayableNode = null;

let selectedPlaybackOption = PlaybackOption.OK_NOTES;

const setSelectedPlaybackOption = (playbackOption: PlaybackOption) => {
  selectedPlaybackOption = playbackOption;
};

/**
 *
 * @return {number}
 */
const getSelectedPlaybackOption = (): number => {
  return selectedPlaybackOption;
};

/**
 *
 * @param {{exerciseNotes: ExerciseNotes, fromBar: Number, toBar: Number, leftHand: Boolean, rightHand: Boolean}} obj
 */
const startListening = async ({
  exerciseNotes,
  fromBar,
  toBar,
  leftHand,
  rightHand,
}: {
  exerciseNotes: ExerciseNotes;
  fromBar: number;
  toBar: number;
  leftHand: boolean;
  rightHand: boolean;
}) => {
  try {
    const midiInput = await getSelectedMidiDevice();

    if (midiInput == null) return;

    if (!midiInput.hasListener("noteon", "all", noteOnEventHandler))
      midiInput.addListener("noteon", "all", noteOnEventHandler);

    const node = buildExpectedNotesPath({
      exerciseNotes,
      fromBar,
      toBar,
      leftHand,
      rightHand,
    });
    startListeningToNode(node);
  } catch (err) {
    throw err;
  }
};

/**
 *
 * @param {PlayableNode} node
 */
const startListeningToNode = (node: PlayableNode) => {
  rootNode = node;
  currentNode = node;
  node.startExpecting();
};

// eslint-disable-next-line valid-jsdoc
/**
 *
 * @param {import("webmidi").InputEventNoteon} e
 */
const noteOnEventHandler = (e: InputEventNoteon) => {
  {
    if (selectedPlaybackOption == PlaybackOption.ALWAYS) {
      midiPlayer.playNote(e.note.name + e.note.octave);
    }

    const keyResult = currentNode.handleNewKeydown(e);

    if (keyResult && selectedPlaybackOption == PlaybackOption.OK_NOTES) {
      midiPlayer.playNote(e.note.name + e.note.octave);
    }

    if (currentNode.isFulfilled) {
      debug(currentNode + " fulfilled");
      currentNode.reset();
      if (currentNode.nextNode.nextNode == null) {
        resetPaintedNotes();
        currentNode = rootNode;
        debug("Completed all expected notes path!");
      } else {
        currentNode = currentNode.nextNode;
      }

      debug("Expecting " + currentNode);
      currentNode.startExpecting();
    }
  }
};

/**
 * Build a LinkedList-like data structure where the root is the first node to play.
 * @param {{exerciseNotes: ExerciseNotes, fromBar: number, toBar: number, leftHand: boolean, rightHand: boolean}} obj
 * @return {PlayableNode}
 */
const buildExpectedNotesPath = ({
  exerciseNotes,
  fromBar,
  toBar,
  leftHand,
  rightHand,
}: {
  exerciseNotes: ExerciseNotes;
  fromBar: number;
  toBar: number;
  leftHand: boolean;
  rightHand: boolean;
}): PlayableNode => {
  let root = new PlayableNode(0);
  let currentNode = root;
  let prevNode = null;

  // chooses bars range to include on the path based on which bars the user wants to play
  const firstBar = fromBar != null && fromBar >= 0 ? fromBar : 0;
  const lastBar =
    toBar != null && toBar >= fromBar
      ? toBar
      : exerciseNotes.measures.length - 1;
  for (let m = firstBar; m <= lastBar; m++) {
    const measure = exerciseNotes.measures[m];
    const voices = [];

    leftHand = measure.staves.length > 1 && leftHand;
    if (rightHand) {
      voices.push(...measure.staves[0].voices);
    }
    if (leftHand) {
      voices.push(...measure.staves[1].voices);
    }

    /**
     * index idx[i]=X means that for the ith voice, the Xth note is the next to be added
     */
    const idx = voices.map((voice) => 0);
    let burnedOutVoices = 0;
    while (burnedOutVoices < voices.length) {
      let minIndex = -1;
      let minStartingPoint = -1;

      for (let i = 0; i < idx.length; i++) {
        if (
          idx[i] < voices[i].notes.length &&
          (minIndex < 0 ||
            voices[i].notes[idx[i]].measureStartingPoint <
            voices[minIndex].notes[idx[minIndex]].measureStartingPoint)
        ) {
          minIndex = i;
          minStartingPoint = voices[i].notes[idx[i]].measureStartingPoint;
        }
      }

      for (let i = 0; i < idx.length; i++) {
        if (
          idx[i] >= voices[i].notes.length ||
          voices[i].notes[idx[i]].measureStartingPoint > minStartingPoint
        )
          continue;

        if (voices[i].notes[idx[i]].measureStartingPoint == minStartingPoint) {
          currentNode.pushNote(voices[i].notes[idx[i]++]);
          if (idx[i] == voices[i].notes.length) burnedOutVoices++;
        }
      }

      if (currentNode.keysCount === 0) {
        if (currentNode == root) {
          root = new PlayableNode(0);
          currentNode = root;
        } else {
          currentNode = new PlayableNode(currentNode.index);
          prevNode.nextNode = currentNode;
        }
        continue;
      }

      prevNode = currentNode;
      currentNode.nextNode = new PlayableNode(currentNode.index + 1);
      currentNode = currentNode.nextNode;
    }
  }

  return root;
};

export {
  startListening,
  setSelectedPlaybackOption,
  getSelectedPlaybackOption,
  buildExpectedNotesPath,
};
