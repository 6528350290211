import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import fdebug from "debug";
import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getAllNotesWithoutAlterations } from "../../services/keyService";
import progressions from "../../services/randomGenerator/chordProgressions";
import { TENSIONS } from "../../services/randomGenerator/Tensions";
import { MajorKey } from "../../types/MajorKey";
import { MAJOR_KEYS } from "../../types/MajorKeys";
import { NoteDuration } from "../../types/noteDuration";
import { NoteNameWithOctave } from "../../types/NoteNameWithOctave";
import { RandomExerciseConfig } from "../../types/RandomExerciseConfig";
import { Tension } from "../../types/Tension";
import { useStickyState } from "../lib/stickyState";
import { capNumber } from "../lib/utils";
import FigureSelector from "./figureSelector";
import LevelPicker from "./LevelPicker";
import RythmSelector from "./rythmSelector/RythmSelector";
import { DEFAULT_RTYHMS } from "../../data/rythms";
import RythmCreator from "./rythmSelector/RythmCreator";
import { Rythm } from "../../types/Rythm";

const debug = fdebug("app:RandomGenerator.tsx");

/**
 * file  components/exercise.js
 * This is the main component for an exercise, it renders evertything an
 * exercise needs.
 */

const useStyles = makeStyles({
  randomInputContainer: {
    marginTop: "2vh",
    display: "flex",
    flexDirection: "row",
  },
  randomInput: {
    flex: 1,
  },
  randomRangeMidiButton: {},
});

const RandomGenerator = () => {
  const [customParamsExpanded, setCustomParamsExpanded] =
    useState<boolean>(true);

  const [exerciseKey, setExerciseKey] = useStickyState<MajorKey>(
    MAJOR_KEYS.C,
    "randomExerciseKey"
  );
  const [areParamsCustomized, setAreParamsCustomized] = useStickyState<boolean>(
    false,
    "areParamsCustomized"
  );

  const [chordsTension, setChordsTension] = useStickyState<Tension>(
    "TRIAD",
    "randomExerciseChordTension"
  );
  const [chordProgressionIndex, setChordProgressionIndex] =
    useStickyState<number>(0, "chordProgressionIndex");
  const [randomNoteFrom, setRandomNoteFrom] =
    useStickyState<NoteNameWithOctave>("C/4", "randomMelodyNoteFrom");
  const [randomNoteTo, setRandomNoteTo] = useStickyState<NoteNameWithOctave>(
    "C/5",
    "randomMelodyNoteTo"
  );
  const [selectedFigures, setSelectedFigures] = useStickyState<NoteDuration[]>(
    [2, 4],
    "selectedFigures"
  );

  const [selectedRythmIds, setRythmIds] = useStickyState<string[]>([], "selectedRythmIds")

  const [numberOfBars, setNumberOfBars] = useStickyState<number>(
    20,
    "randomExerciseNumberOfBars"
  );

  const getExerciseParams = (): RandomExerciseConfig => {
    return {
      key: exerciseKey,
      numberOfBars: numberOfBars,
      noteFrom: randomNoteFrom,
      noteTo: randomNoteTo,
      tension: chordsTension,
      progressionIndex: chordProgressionIndex,
      rythmIds: selectedRythmIds
    };
  };

  const setExerciseParams = (params: RandomExerciseConfig) => {
    if(params == null) debugger
    setExerciseKey(params.key);
    setRandomNoteFrom(params.noteFrom);
    setRandomNoteTo(params.noteTo);
    setChordsTension(params.tension);
    setChordProgressionIndex(params.progressionIndex);
    setRythmIds(params.rythmIds);
  };

  const toggleCustomParamsExpanded = () => {
    setCustomParamsExpanded(!customParamsExpanded);
  };

  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box>
      <Box sx={{ mr: "2rem" }}>
        <LevelPicker setExerciseParams={setExerciseParams} />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            navigate({
              pathname: "/random",
              search: `?${createSearchParams({
                params: JSON.stringify(getExerciseParams()),
              })}`,
            });
          }}
        >
          Generate exercise
        </Button>

        <Button
          variant="outlined"
          sx={{ ml: "0.5rem" }}
          onClick={toggleCustomParamsExpanded}
        >
          <Typography variant="button">
            {customParamsExpanded ? "Hide Details" : "Customize exercise"}
          </Typography>
        </Button>
      </Box>

      <Collapse in={customParamsExpanded}>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <RythmSelector rythms={DEFAULT_RTYHMS} selectedRythmIds={selectedRythmIds} setSelectedRythmIds={setRythmIds} />
            </Grid>
          <Grid item xs={6} md={3}>
            <div className={classes.randomInputContainer}>
              <FormControl className={classes.randomInput}>
                <InputLabel id="exercise-key-select-label">
                  Exercise key
                </InputLabel>
                <Select
                  labelId="exercise-key-select-label"
                  id="exercise-key-select"
                  value={exerciseKey}
                  label="Exercise key"
                  onChange={(e) => {
                    setExerciseKey(e.target.value as MajorKey);
                  }}
                >
                  {Object.keys(MAJOR_KEYS).map((key) => {
                    return (
                      <MenuItem key={MAJOR_KEYS[key]} value={MAJOR_KEYS[key]}>
                        {MAJOR_KEYS[key]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <div className={classes.randomInputContainer}>
              <FormControl className={classes.randomInput}>
                <TextField
                  label="Number of bars"
                  type="number"
                  value={numberOfBars}
                  onChange={(e) =>
                    setNumberOfBars(capNumber(Number(e.target.value), 0, 200))
                  }
                  inputProps={{ min: 5, max: 200, step: 5 }}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className={classes.randomInputContainer}>
              <FormControl className={classes.randomInput}>
                <InputLabel id="exercise-tensions-select-label">
                  Chords type
                </InputLabel>
                <Select
                  labelId="exercise-tensions-select-label"
                  id="exercise-tensions-select"
                  label="Chords type"
                  value={chordsTension}
                  onChange={(e) => {
                    setChordsTension(e.target.value as Tension);
                  }}
                >
                  {Object.keys(TENSIONS).map((tension) => {
                    return (
                      <MenuItem key={tension} value={tension}>
                        {tension}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.randomInputContainer}>
              <FormControl className={classes.randomInput}>
                <InputLabel id="exercise-chord-progression-label">
                  Chord progression
                </InputLabel>
                <Select
                  labelId="exercise-chord-progression-label"
                  id="exercise-chord-progression"
                  label="Chord progression"
                  value={chordProgressionIndex}
                  onChange={(e) => {
                    setChordProgressionIndex(e.target.value as number);
                  }}
                >
                  {progressions.map((progression, index) => {
                    return (
                      <MenuItem key={index} value={index}>
                        {progression.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className={classes.randomInputContainer}>
              {/* <IconButton className={classes.randomRangeMidiButton}>
                <StraightenIcon color="primary" />
              </IconButton> */}
              <Autocomplete
                disableClearable
                className={classes.randomInput}
                renderInput={(params) => (
                  <TextField {...params} label="Melody note from" />
                )}
                options={getAllNotesWithoutAlterations(4)}
                value={randomNoteFrom}
                onChange={(event, newValue) => {
                  setRandomNoteFrom(newValue as NoteNameWithOctave);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.randomInputContainer}>
              {/* <IconButton className={classes.randomRangeMidiButton}>
                <StraightenIcon color="primary" />
              </IconButton> */}
              <Autocomplete
                disableClearable
                className={classes.randomInput}
                renderInput={(params) => (
                  <TextField {...params} label="Melody note to" />
                )}
                options={getAllNotesWithoutAlterations(4)}
                value={randomNoteTo}
                onChange={(event, newValue) => {
                  setRandomNoteTo(newValue as NoteNameWithOctave);
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
};

export default RandomGenerator;
