/** */
export default class TimeSignature {
  /**
   * Construct a Timesignature class
   * @param {String} time
   */
  constructor(time = null) {
    this.time = time;
    this._timeDen = null;
    this._timeNum = null;
    if (time != null) [this._timeNum, this._timeDen] = time.split('/');
  }
}
