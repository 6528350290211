'use-stric';
// for type intellisense
// eslint-disable-next-line
import ExerciseNotes from './ExerciseNotes';

import fdebug from 'debug';
const debug = fdebug('app:ScoreService');

import Score from './Score';

/**
 * @type {Score}
 */
let score;
/**
 *
 * @param {String} containerId HTML element id of the container where the score is loaded
 * @param {Object} exercise JSON type(??) object containing the exercise;
 * @return {ExerciseNotes} loaded score object.
 */
export function drawExercise(containerId, exercise) {
  try {
    score = new Score('scoreContainer');
    score.time = exercise.config.time;
    let loadedExercise = score.addExercise(exercise);
    return loadedExercise;
  } catch (e) {
    console.error('Error loading exercise: ' + e.message);
    console.error(e.stackTrace)
  }
}

/** */
export function resetTimemarks() {
  score.resetTimemarks();
}

/**
 *
 * @param {Number} beat sets the beat at where it should start;
 * @param {Number} barFrom sets the beat at where it should start;
 * @param {Number} barTo sets the beat at where it should start;
 */
export function setStartPlayingAt(beat, barFrom, barTo) {
  score.setStartPlayingAt(beat, barFrom, barTo);
}
