export enum MAJOR_KEYS {
  Cb = "Cb",
  Gb = "Gb",
  Db = "Db",
  Ab = "Ab",
  Eb = "Eb",
  Bb = "Bb",
  F = "F",
  C = "C",
  G = "G",
  D = "D",
  A = "A",
  E = "E",
  B = "B",
  F_SHARP = "F#",
  C_SHARP = "C#",
}
