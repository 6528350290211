/**
 * file  components/exercise.js
 * This is the main component for an exercise, it renders evertything an
 * exercise needs.
 */

import { Box, Grid, Typography } from "@mui/material";
import { useSearchParams, useLocation } from "react-router-dom";

import fdebug from "debug";
import React, { useState, Dispatch, SetStateAction } from "react";
import { resetPaintedNotes } from "../services/context";
import { initAudioContext } from "../services/IO/audioContext";
import { Link } from "react-router-dom";

import "./exercise.css";
// import * as midiService from "../services/IO/midiService";
import {
  getSelectedPlaybackOption,
  setSelectedPlaybackOption,
  startListening,
} from "../services/IO/midiListener";

import {
  startChallengeMode,
  stopChallengeMode,
} from "../services/IO/challengeMode";

import {
  getMidiDevices,
  getSelectedDeviceIndex,
  onMidiDevicesChange,
  setDefaultDevice,
} from "../services/IO/midiService";
import midiPlayer from "../services/IO/player";
// services (imports that do things)
import metronome from "../services/metronome";
import ExerciseNotes from "../services/score/ExerciseNotes";
import * as scoreService from "../services/score/service";
import TimeSignature from "../services/score/TimeSignature";
import { BarSelector } from "./controls/BarSelector";
import { MidiListener } from "./controls/MidiListener";
import { ScorePlayer } from "./controls/ScorePlayer";
// Components imports
import MetronomeComponent from "./metronome";
import ScoreComponent from "./score";
import { generateRandomExercise } from "../services/randomGenerator/randomGenerator";
import { getExerciseById } from "../services/exercises";
import FullControlsToggler from "./controls/FullControlsToggler";
import Instructions from "./instructions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BackButton from "./backButton";
import { RandomExerciseConfig } from "../types/RandomExerciseConfig";
import { HandSelector } from "./controls/HandSelector";
import { useStickyState } from "./lib/stickyState";

const debug = fdebug("app:exerciseComponent");

const PlayerComponent = ({
  setShouldRefresh,
}: {
  setShouldRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [readyToPlay, setReadyToPlay] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [playingChallenge, setPlayingChallenge] = useState(false);
  const [playLeftHand, setPlayLeftHand] = useStickyState(true, "leftHandOn");
  const [playRightHand, setPlayRightHand] = useStickyState(true, "rightHandOn");
  const [metronomeGracePeriod, setMetronomeGracePeriod] = useState(false);
  const [paintNotes, setPaintNotes] = useState(true);
  const [loop, setLoop] = useState(true);

  const [playFromBar, setPlayFromBar] = useState(null);
  const [playToBar, setPlayToBar] = useState(null);

  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const [exercise, setExercise] = useState(null);

  const [showFullControls, setShowFullcontrols] = useState(false);

  const [playbackOption, setPlaybackOption] = useStickyState(
    getSelectedPlaybackOption(),
    "playbackOption"
  );

  /**
   * @type [import("webmidi").Input[], function]
   */
  const [midiInputs, setMidiInputs] = useState([]);

  /**
   * state to store the index of the selected input from the midiInputs array
   * @type number
   */
  const [selectedInputIndex, setSelectedInputIndex] = useState(null);

  /** @type [ExerciseNotes, Function] */
  const [exerciseNotes, setExerciseNotes]: [
    ExerciseNotes,
    Dispatch<SetStateAction<ExerciseNotes>>
  ] = useState(null);

  // set exercise based on url params
  const randomExerciseSearchParams = searchParams.get("params");
  const exerciseSearchParams = searchParams.get("id");
  React.useEffect(() => {
    if (pathname === "/random") {
      const exerciseParams: RandomExerciseConfig = JSON.parse(
        randomExerciseSearchParams
      );

      setExercise(generateRandomExercise(exerciseParams));
    } else if (pathname === "/exercise") {
      setExercise(getExerciseById(exerciseSearchParams));
    }
  }, [pathname, searchParams]);

  React.useEffect(() => {
    setSelectedPlaybackOption(playbackOption);
  }, [playbackOption]);

  // Render exercise
  React.useEffect(() => {
    if (exercise == null) return;
    initAudioContext();
    setExerciseNotes(scoreService.drawExercise("scoreBox", exercise));

    getMidiDevices().then((inputs) => {
      setMidiInputs(inputs);
      if (inputs.length > 0 && selectedInputIndex == null) {
        setDefaultDevice().then(() => {
          setSelectedInputIndex(getSelectedDeviceIndex());
        });
      } else {
        setSelectedInputIndex(getSelectedDeviceIndex());
      }
    });

    onMidiDevicesChange((inputs, selectedInputIndex) => {
      const noDevices = midiInputs == null || midiInputs.length == 0;

      setMidiInputs(inputs);
      setSelectedInputIndex(selectedInputIndex);

      if (midiInputs == null || midiInputs.length == 0) {
        resetPaintedNotes();
      }
      // else if (noDevices && inputs.length > 0) {
      //   listenUserInput();
      // }
    });

    midiPlayer.loadInstrument(metronome.getAudioContext(), () => {
      setReadyToPlay(true);
      debug("intrument loaded");
    });
  }, [exercise]);

  // React.useEffect(() => {
  //   if (exerciseNotes != null) listenUserInput();
  // }, [exerciseNotes]);

  /** */
  const playExercise = async () => {
    // load the instrument, start the metronome, then start the player
    // coordinated with the metronome
    setShowFullcontrols(false);

    const { firstBeatTime, nextMeasureFirstBeatTime } = metronome.start();

    const measureToStartPlaying = metronomeGracePeriod
      ? nextMeasureFirstBeatTime
      : firstBeatTime;
    scoreService.setStartPlayingAt(
      metronomeGracePeriod ? exerciseNotes.timeNumerator : null,
      playFromBar,
      playToBar
    );

    midiPlayer.playNotes({
      exNotes: exerciseNotes,
      timeToStart: measureToStartPlaying,
      leftHand: playLeftHand,
      rightHand: playRightHand,
      barFrom: playFromBar,
      barTo: playToBar,
      paintNotes: paintNotes,
      onEnd: () => {
        loop ? restartPlayingExcercise() : stopPlayingExcercise();
      },
    });

    setPlaying(true);
  };

  React.useEffect(() => {
    setShouldRefresh(true);
  }, []);

  /** */
  const listenUserInput = async () => {
    debug("Listening user input");
    setShowFullcontrols(false);
    resetPaintedNotes();
    if (playing) {
      stopPlayingExcercise();
    }
    await startListening({
      exerciseNotes: exerciseNotes,
      fromBar: playFromBar,
      toBar: playToBar,
      leftHand: playLeftHand,
      rightHand: playRightHand,
    });
  };

  const stopChallenge = () => {
    metronome.stop();
    scoreService.resetTimemarks();
    stopChallengeMode();
    setPlayingChallenge(false);
  };

  const startChallenge = async () => {
    setShowFullcontrols(false);
    resetPaintedNotes();
    setPlayingChallenge(true);
    const { nextMeasureFirstBeatTime } = metronome.start();

    const startingTime = nextMeasureFirstBeatTime;

    scoreService.setStartPlayingAt(
      exerciseNotes.timeNumerator,
      playFromBar,
      playToBar
    );

    startChallengeMode({
      exerciseNotes: exerciseNotes,
      timeToStart: startingTime,
      fromBar: playFromBar,
      toBar: playToBar,
      leftHand: playLeftHand,
      rightHand: playRightHand,
    });
  };

  /** */
  const restartPlayingExcercise = () => {
    metronome.stop();
    midiPlayer.stop();
    scoreService.resetTimemarks();
    playExercise();
  };

  /** */
  const stopPlayingExcercise = () => {
    midiPlayer.stop();
    metronome.stop();
    scoreService.resetTimemarks();
    setPlaying(false);
  };

  if (exercise == null) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      sx={{
        height: "97vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{ flex: "0 1 auto", boxShadow: 2, zIndex: "5" }}
        className="controls-container"
      >
        <Grid container spacing={2} className="controls-grid-root">
          <Grid item xs={2} className="more-options">
            <BackButton />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Instructions />
              <FullControlsToggler
                showFullControls={showFullControls}
                setShowFullControls={setShowFullcontrols}
              />
            </Box>
          </Grid>
          {/* Metronome menu section */}
          <Grid item xs={3} className="exercise-control">
            <Typography variant="overline">Metronome</Typography>
            <MetronomeComponent
              timeSignature={new TimeSignature(exercise.config.time)}
              playing={playing}
              showFullControls={showFullControls}
            />
          </Grid>
          <Grid item xs={2} className="exercise-control">
            <Typography variant="overline">Hands & bars</Typography>
            <BarSelector
              playToBar={playToBar}
              playFromBar={playFromBar}
              setPlayFromBar={setPlayFromBar}
              setPlayToBar={setPlayToBar}
              showFullControls={showFullControls}
            />
            <HandSelector
              leftHand={playLeftHand}
              rightHand={playRightHand}
              setLeftHand={setPlayLeftHand}
              setRightHand={setPlayRightHand}
            />
          </Grid>

          {/* Player menu section */}
          <Grid item xs={2} className="exercise-control">
            <Typography variant="overline">Listen</Typography>
            <ScorePlayer
              playing={playing}
              playLeftHand={playLeftHand}
              setPlayLeftHand={setPlayLeftHand}
              playRightHand={playRightHand}
              setPlayRightHand={setPlayRightHand}
              paintNotes={paintNotes}
              setPaintNotes={setPaintNotes}
              metronomeGracePeriod={metronomeGracePeriod}
              setMetronomeGracePeriod={setMetronomeGracePeriod}
              loop={loop}
              setLoop={setLoop}
              readyToPlay={readyToPlay}
              playExercise={playExercise}
              stopPlayingExcercise={stopPlayingExcercise}
              showFullControls={showFullControls}
              optionsDisabled={playing || playingChallenge}
            />
          </Grid>
          <Grid item xs={3} className="exercise-control">
            <Typography variant="overline">Practice</Typography>
            <MidiListener
              playing={playingChallenge}
              listenLeftHand={playLeftHand}
              setListenLeftHand={setPlayLeftHand}
              listenRightHand={playLeftHand}
              setListenRightHand={setPlayRightHand}
              listenUserInput={listenUserInput}
              midiInputs={midiInputs}
              selectedDeviceIndex={selectedInputIndex}
              playbackOption={playbackOption}
              setPlaybackOption={setPlaybackOption}
              startChallenge={startChallenge}
              stopChallenge={stopChallenge}
              showFullControls={showFullControls}
              optionsDisabled={playing || playingChallenge}
            />
          </Grid>
        </Grid>
      </Box>
      <Box id="visibleScore" sx={{ flex: "1 1 auto", overflow: "scroll" }}>
        <ScoreComponent />
      </Box>
    </Box>
  );
};

export default PlayerComponent;
