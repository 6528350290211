import { SvgIcon } from "@mui/material";
import * as React from "react";
import { Dot } from "./dot";

const Round = ({ className, dotted = false }: { className: string, dotted: boolean }) => {
  return (
    <SvgIcon className={className} viewBox="0 0 20 40">
      <g className="vf-note">
        <g className="vf-notehead">
          <path
            stroke-width="0.3"
            stroke="none"
            fill="black"
            stroke-dasharray="none"
            d="M 1 30 M 8.6939 24.8333 C 9.0028 24.7771 9.3117 24.7771 9.6206 24.7771 C 14.0572 24.7771 17.7638 26.7427 18.2973 29.3822 C 18.3254 29.635 18.3815 29.8596 18.3815 30.0281 C 18.3815 32.9203 14.5626 35.251 9.6486 35.251 C 4.7346 35.251 1 32.8642 1 30.0281 C 1 29.6911 1.0281 29.3542 1.1404 28.9891 C 1.8143 26.7708 4.9593 25.0579 8.6939 24.8333 M 9.1151 25.4791 C 8.9747 25.451 8.7782 25.451 8.6378 25.451 C 6.8687 25.451 5.8017 26.9674 5.8017 28.8487 C 6 29 6.1106 30.9547 6.7564 32.0498 C 7.8234 33.7346 9.3678 34.577 10.6876 34.577 C 11.8108 34.577 12.7936 33.9874 13.2429 32.7518 C 13.4394 32.2464 13.5237 31.741 13.5237 31.2074 C 13.5237 28.6802 11.5862 25.8722 9.1151 25.4791"
          ></path>
        </g>
      </g>
      {dotted && <Dot />}
    </SvgIcon>
  );
};

export default Round;
