import React from "react";
import ReactDOM from "react-dom";
import RootComponent from "./components/root";

import { Theme } from "@mui/material/styles";

import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material";
import { BrowserRouter } from "react-router-dom";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { cyan } from "@mui/material/colors";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAkz3Ogc3FQWv1uK-nDiEoE6BNBCrCBpEE",
  authDomain: "midi-sight-reading.firebaseapp.com",
  projectId: "midi-sight-reading",
  storageBucket: "midi-sight-reading.appspot.com",
  messagingSenderId: "600721267364",
  appId: "1:600721267364:web:f84247c7a7f1ba52742431",
  measurementId: "G-S78PCDCVYF",
};

// @ts-ignore
if (process.env.NODE_ENV === "development") localStorage.debug = "app:*";

const theme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#434343",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#434343",
      contrastText: "#fff",
      grayText: "#616161",
    },
    secondary: cyan,
  },
});

window.addEventListener("load", () => {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  ReactDOM.render(
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <RootComponent />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>,
    document.getElementById("root")
  );
});
