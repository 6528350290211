import {
  chopinsNocturne20,
  berceusev2,
  multiVoice,
  moonlightSonata,
} from "./exampleExercises";

const exercises = {
  chopinsNocturne20,
  berceusev2,
  multiVoice,
  moonlightSonata
};

/**
 * @typedef {{name:string, description: string, exercises: import("../../types/exerciseInput/exercise").InputExercise[]}} Category
 */

/**
 * @type {[Category]}
 */
const categories = [
  {
    name: "classical",
    description: "Work in progress... Examples:",
    // @ts-ignore
    exercises: [moonlightSonata, berceusev2, chopinsNocturne20],
  },
];

const exercisesArray = [
  moonlightSonata,
  chopinsNocturne20,
  berceusev2,
];

export { exercises, categories, exercisesArray };
