import fdebug from "debug";
// eslint-disable-next-line no-unused-vars
const debug = fdebug("app:midiPlayer");

/**
 *
 * @param {String} noteId note in C#/4 format
 */
/**
 * @class MidiNote
 */
export default class MidiNote {
  name: string;
  number: string;
  octave: string;

  constructor({
    name,
    number,
    octave,
  }: {
    name: string;
    number: string;
    octave: string;
  }) {
    this.name = name;
    this.number = number;
    this.octave = octave;
  }

  /**
   * @return {String} gets note in C/4 format
   */
  getNoteString(): string {
    return `${this.name}/${this.octave}`;
  }
}
