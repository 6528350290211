import Black from "./black";
import White from "./white";
import Round from "./round";
import Quaiver from "./quaiver";
import { NoteDuration } from "../../types/noteDuration";
import Sixteenth from "./sixteenth";

const durationToComponent = {
  1: Round,
  2: White,
  4: Black,
  8: Quaiver,
  16: Sixteenth,
}

export const getComponentForDuration = (duration: NoteDuration) => (durationToComponent[duration])