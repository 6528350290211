import React from 'react'
import { Rythm } from '../../../types/Rythm'
import { Box } from '@mui/material'
import { getComponentForDuration } from '../../figures'

const RythmPreview = ({rythm}: {rythm: Rythm}) => {
    return <Box sx ={{display: 'flex', flexDirection: 'row'}}>
        {rythm.elements.map(elem => {
            const Icon = getComponentForDuration(elem.duration)
            return <Box><Icon dotted={elem.dotted} /></Box>
        })}
    </Box>
}

export default RythmPreview