import { ChordProgression } from "../../types/ChordProgression";

const progressions: ChordProgression[] = [
  {
    // 2-5-1
    name: "II-V-I",
    length: 4,
    chords: [
      { degree: 2, length: 1 },
      { degree: 5, length: 1 },
      { degree: 1, length: 1 },
      { degree: 1, length: 1 },
    ],
  },
  {
    name: "I-V-VI-IV",
    length: 4,
    chords: [
      { degree: 1, length: 1 },
      { degree: 5, length: 1 },
      { degree: 6, length: 1 },
      { degree: 4, length: 1 },
    ],
  },
  {
    name: "I-IV-I-V",
    length: 4,
    chords: [
      { degree: 1, length: 1 },
      { degree: 4, length: 1 },
      { degree: 1, length: 1 },
      { degree: 5, length: 1 },
    ],
  },
];

export default progressions;
