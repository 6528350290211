/**
 * @type {import("../../../types/exerciseInput/exercise").InputExercise}
 */
const exercise = {
  name: "Beethoveen's Moonlight Sonata first movement",
  id: "examples~moonlightSonata1",
  config: {
    time: "4/4",
    keySignature: "E",
    clefs: ["treble", "bass"],
  },
  measures: [
    {
      staves: [
        {
          voices: [
            {
              notes: [
                {
                  duration: 12,
                  figure: 8,
                  keys: ["G/3"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["C/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["E/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["G/3"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["C/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["E/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["G/3"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["C/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["E/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["G/3"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["C/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["E/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          voices: [
            {
              notes: [
                {
                  duration: 1,
                  keys: ["C/2", "C/3"],
                }
              ]
            }
          ]
        }
      ]
    },
    {
      staves: [
        {
          voices: [
            {
              notes: [
                {
                  duration: 12,
                  figure: 8,
                  keys: ["G/3"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["C/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["E/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["G/3"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["C/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["E/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["G/3"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["C/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["E/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["G/3"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["C/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["E/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          voices: [
            {
              notes: [
                {
                  duration: 1,
                  keys: ["B/1", "B/2"],
                }
              ]
            }
          ]
        }
      ]
    },
    {
      staves: [
        {
          voices: [
            {
              notes: [
                {
                  duration: 12,
                  figure: 8,
                  keys: ["A/3"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["C/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["E/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["A/3"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["C/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["E/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["A/3"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["Dn/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["F/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["A/3"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["Dn/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["F/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          voices: [
            {
              notes: [
                {
                  duration: 2,
                  keys: ["A/1", "A/2"],
                },
                {
                  duration: 2,
                  keys: ["F/1", "F/2"],
                }
              ]
            }
          ]
        }
      ]
    },
    {
      staves: [
        {
          voices: [
            {
              notes: [
                {
                  duration: 12,
                  figure: 8,
                  keys: ["G/3"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["B#/3"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["F/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["G/3"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["C/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["E/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["G/3"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["C/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["D#/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["F/3"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["B#/3"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                },
                {
                  duration: 12,
                  figure: 8,
                  keys: ["D#/4"],
                  options: {
                    timeModification: {
                      actualNotes: 3,
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          voices: [
            {
              notes: [
                {
                  duration: 2,
                  keys: ["G/1", "G/2"],
                },
                {
                  duration: 2,
                  keys: ["G/1", "G/2"],
                }
              ]
            }
          ]
        }
      ]
    }

  ],
};

export default exercise;
