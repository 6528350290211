import { SvgIcon } from "@mui/material";
import * as React from "react";
import { Dot } from "./dot";

const Sixteenth = ({ className, dotted = false }: { className: string, dotted: boolean }) => {
  return (
    <SvgIcon className={className} viewBox="0 0 20 40">
      <g className="vf-note">
        <g id="vf-stem" transform="matrix(1, 0, 0, 1, -2.064732, -1.278005)">
          <path stroke-width="1.5" fill="none" stroke="black" stroke-dasharray="none" d="M 14 31 L 14 4"></path>
        </g>
        <g id="vf-notehead" transform="matrix(1, 0, 0, 1, -2.064732, -1.278005)">
          <path stroke-width="0.3" stroke="none" fill="black" stroke-dasharray="none" d="M 10.0295 25.7771 C 10.1418 25.7771 10.3103 25.7771 10.3384 25.7771 C 10.3384 25.7771 10.3664 25.7771 10.3664 25.7771 C 10.3664 25.7771 10.5349 25.7771 10.6753 25.7771 C 13.2025 25.9175 14.6908 27.5742 14.6908 29.6522 C 14.6908 30.663 14.3538 31.8143 13.5114 32.9094 C 11.9108 35.0716 9.2994 36.251 7.0249 36.251 C 5.2559 36.251 3.7396 35.549 3.0095 34.1169 C 2.7848 33.5553 2.6725 33.0218 2.6725 32.4321 C 2.6725 29.3714 6.0983 25.9737 10.0295 25.7771"></path>
        </g>
        <g id="vf-flag" pointer-events="bounding-box" transform="matrix(1, 0, 0, 1, -2.064732, -1.278005)">
          <path stroke-width="0.3" fill="black" stroke="none" stroke-dasharray="none" d="M 15.1301 3.8 M 14.6583 6.9646 L 14.6583 3.8983 L 14.7369 3.8983 C 14.9532 3.8983 15.0907 4.0359 15.1301 4.2717 C 15.5429 6.2373 16.7812 8.3798 18.4913 9.9916 C 20.3978 11.7803 21.361 14.2373 21.361 16.773 C 21.361 19.171 20.5158 21.6673 18.8057 23.7312 C 18.5109 24.1243 18.4913 24.1243 18.2947 24.1243 C 18.0588 24.1243 17.9016 23.9474 17.9016 23.7115 C 17.9016 23.5346 17.9212 23.4953 18.1177 23.2791 C 19.6116 21.4707 20.3586 19.2889 20.3586 17.2054 C 20.3586 15.3577 19.7689 13.5887 18.5502 12.2521 C 17.8033 11.4265 16.4274 10.6403 14.6779 10.031 C 14.6779 10.0113 14.6583 9.402 14.6583 6.9646"></path>
        </g>
        <g id="vf-flag" pointer-events="bounding-box" transform="matrix(1.052938, 0, 0, 1, -2.840709, 5.68957)">
          <path stroke-width="0.3" fill="black" stroke="none" stroke-dasharray="none" d="M 15.1301 3.8 M 14.6583 6.9646 L 14.6583 3.8983 L 14.7369 3.8983 C 14.9532 3.8983 15.0907 4.0359 15.1301 4.2717 C 15.5429 6.2373 16.7812 8.3798 18.4913 9.9916 C 20.3978 11.7803 21.361 14.2373 21.361 16.773 C 21.361 19.171 20.5158 21.6673 18.8057 23.7312 C 18.5109 24.1243 18.4913 24.1243 18.2947 24.1243 C 18.0588 24.1243 17.9016 23.9474 17.9016 23.7115 C 17.9016 23.5346 17.9212 23.4953 18.1177 23.2791 C 19.6116 21.4707 20.3586 19.2889 20.3586 17.2054 C 20.3586 15.3577 19.7689 13.5887 18.5502 12.2521 C 17.8033 11.4265 16.4274 10.6403 14.6779 10.031 C 14.6779 10.0113 14.6583 9.402 14.6583 6.9646"></path>
        </g>
      </g>
      {dotted && <Dot />}

    </SvgIcon >
  );
};

export default Sixteenth;
