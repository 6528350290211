import { Box, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { Dispatch, Fragment, SetStateAction } from "react";
import PanTool from "@mui/icons-material/PanTool";

interface HandSelectorProps {
  leftHand: boolean;
  rightHand: boolean;
  setLeftHand: Dispatch<SetStateAction<boolean>>;
  setRightHand: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles({
  disabled: {
    color: "lightgray",
  },
  mirrored: {
    transform: "scaleX(-1)",
  },
  container: {
    display: "flex",
    justifyContent: "space-around",
  },
});

export const HandSelector = ({
  leftHand,
  rightHand,
  setLeftHand,
  setRightHand,
}: HandSelectorProps) => {
  const classes = useStyles();
  const rightHandChange = () => {
    if (!leftHand && rightHand) {
      setLeftHand(true);
    }

    setRightHand(!rightHand);
  };

  const leftHandChange = (e) => {
    if (!rightHand && leftHand) {
      setRightHand(true);
    }

    setLeftHand(!leftHand);
  };

  return (
    <Box
      className={`controls-main-section controls-hand-selector ${classes.container}`}
    >
      <IconButton
        onClick={leftHandChange}
        className={`${classes.mirrored} ${leftHand ? "" : classes.disabled}`}
      >
        <PanTool />
      </IconButton>

      <IconButton
        onClick={rightHandChange}
        className={rightHand ? "" : classes.disabled}
      >
        <PanTool />
      </IconButton>
    </Box>
  );
};
