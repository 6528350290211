import * as React from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
  },
}));

const BackButton = () => {
  const classes = useStyles();
  return (
    <Link to="/">
      <ArrowBackIcon className={classes.link} />
    </Link>
  );
};

export default BackButton;
